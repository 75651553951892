/**
 * An registry that hold layout,input and viz apis
 */
export default class ApiRegistry {
    constructor() {
        this.layoutApi = null;
        this.visualizationApis = {};
        this.inputApis = {};
    }

    registerLayoutApi(layoutApi) {
        this.layoutApi = layoutApi;
    }

    registerVisualizationApi(vizId, api) {
        this.visualizationApis[vizId] = api;
    }

    registerInputApi(inputId, api) {
        this.inputApis[inputId] = api;
    }

    removeLayoutApi() {
        this.layoutApi = null;
    }

    removeVisualizationApi(vizId) {
        delete this.visualizationApis[vizId];
    }

    removeInputApi(inputId) {
        delete this.inputApis[inputId];
    }

    getLayoutApi() {
        return this.layoutApi;
    }

    getVisualizationApi(vizId) {
        return this.visualizationApis[vizId];
    }

    getInputApi(inputId) {
        return this.inputApis[inputId];
    }

    teardown() {
        this.removeLayoutApi();
        this.visualizationApis = {};
        this.inputApis = {};
    }
}
