/* eslint-disable class-methods-use-this,no-unused-vars */
import isEqual from 'lodash/isEqual';

/**
 * Base class of a DataSource
 */
export default class DataSource {
    /**
     * Create a new DataSource Instance
     * @param {Object} options DataSoure options such as search string, earliest/latest time
     * @param {Object} context contextual information such as api key or scope
     * @param {Object} meta metadata of this datasource
     */
    constructor(options = {}, context = {}, meta = {}) {
        this.options = options;
        this.context = context;
        this.meta = meta;
    }

    /**
     * setup the DataSource, this is the place where you can setup your connection or create the search job.
     * setup will be called only once per DataSource.
     * @return {Promise}
     * @public
     */
    setup() {
        return Promise.resolve();
    }

    /**
     * Request a Data Stream represent by an Observable.
     * Once the Observable was created and returned,
     * it's the DataSource itself responsible for 'pushing' the data out via Observable.next() function.
     * Override this function to implement your own data fetching logic.
     *
     * @param {Object} [requestParams] RequestParams
     * @param {Number} [requestParams.offset] result offset
     * @param {Number} [requestParams.count] result count, use with offset to return data in pages
     * @param {Object} [requestParams.sort] result sorting, key indicate the sorting fields and value must be one of ['none', 'asc', 'desc']
     * @param {Boolean} [requestParams.requireTotalCount] true if TotalCount is required in meta
     * @param {Boolean} [requestParams.progress] true to emit progress data, false to only return data when search is finalized
     * @return {Observable}
     * @public
     */
    request(requestParams = {}) {
        return observer => {
            observer.complete();
            return () => {
                // cleanup
            };
        };
    }

    /**
     * teardown the DataSource, clear up connection or other resources
     * @return {Promise}
     * @public
     */
    teardown() {
        return Promise.resolve();
    }

    /**
     * @param {DataSource} another another DataSource to compare with
     * @return {Boolean} true if the these two DataSources are considered equal.
     * @public
     */
    equals(another) {
        return this.constructor === another.constructor && isEqual(this.options, another.options);
    }
}
