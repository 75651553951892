/* eslint-disable no-restricted-syntax, guard-for-in */
import get from 'lodash/get';
import console, { logfmt } from '@splunk/dashboard-utils/console';
import { put, select, takeLatest, takeEvery, cancelled } from 'redux-saga/effects';
import { selectInputState, setInputState, updateInputState } from '../redux/state/input';
import { createInputState, createNextState } from '../../utils/input';
import { selectInputs, selectLayout } from '../redux/definition';
import { setToken, selectSubmittedTokens, selectStashedTokens } from '../redux/tokens';
import { inputChanged, initialize, inputValueChanged, triggerEvent } from './sagaActions';
import { contains } from '../../utils/token';
import { resetStore } from '../redux';

const hasSubmitButton = layout => get(layout, ['options', 'submitButton'], false);

const getTokenNamespace = inputDef => get(inputDef, ['options', 'tokenNamespace'], 'default');

/**
 * handle input value change, it's gonna update the input state ( value ) and set tokens
 * @param {*} action
 * @param {*} inputDefs
 * @param {*} sagaContext
 */
function* handleValueChange(inputDefs, sagaContext, action) {
    const { id, value, eventId } = action.payload;
    const inputDef = inputDefs[id];
    const layout = yield select(selectLayout);
    // given the new value, compute the next input state.
    const nextState = createNextState({ value, inputDef });
    yield put(updateInputState(id, nextState));
    // dispatch value change event.
    // token submission will be handled in event saga as part of event handling pipeine
    const input = sagaContext.preset.findInput(inputDef.type);
    const tokens = input && input.valueToTokens ? input.valueToTokens(nextState.value, inputDef.options) : {};
    // check if there's submit button for defined namespace.
    const tokenNamespace = getTokenNamespace(inputDef);
    // we only have submit button for default namespace now
    const submit = !(tokenNamespace === 'default' && hasSubmitButton(layout));
    yield put(
        triggerEvent(
            id,
            'input.change',
            {
                value: nextState.value,
                tokens,
                tokenNamespace,
                submit,
            },
            eventId // the event dispatched from ui component
        )
    );
}

/**
 * Given the new inputState, compute and update token values.
 * We need to perform this because new inputState may contains default/initial value so we need to
 * populate the tokens accordingly
 * @param {*} inputState
 * @param {*} inputDefs
 * @param {*} sagaContext
 */
function* handleInitialTokenSubmit(inputState, inputDefs, sagaContext) {
    const layout = yield select(selectLayout);
    const submittedTokens = yield select(selectSubmittedTokens);
    const stashedTokens = yield select(selectStashedTokens);
    for (const id in inputState) {
        const inputDef = inputDefs[id];
        const tokenNamespace = getTokenNamespace(inputDef);
        const submit = !(tokenNamespace === 'default' && hasSubmitButton(layout));
        const input = sagaContext.preset.findInput(inputDef.type);
        const tokens =
            input && input.valueToTokens ? input.valueToTokens(inputState[id].value, inputDef.options) : {};
        if (!contains((submit ? submittedTokens : stashedTokens)[tokenNamespace], tokens)) {
            yield put(setToken(tokens, tokenNamespace, submit));
        }
    }
}

/**
 * handle input definiton change
 * @param {*} sagaContext
 */
function* handleInputDefinitionChanged(sagaContext) {
    const inputDefs = yield select(selectInputs);
    const currentInputState = yield select(selectInputState);
    // default value and initialValue will be taken care here.
    const inputState = createInputState(currentInputState, inputDefs);
    yield put(setInputState(inputState));
    // handle initial submission
    yield handleInitialTokenSubmit(inputState, inputDefs, sagaContext);
    // setup value change handler for inputs
    yield takeEvery(inputValueChanged, handleValueChange, inputDefs, sagaContext);
}

export default function* inputSaga(sagaContext) {
    try {
        yield takeLatest([resetStore, inputChanged, initialize], handleInputDefinitionChanged, sagaContext);
    } catch (error) {
        if (!(yield cancelled())) {
            console.error(...logfmt`Caught error: ${error}`);
        }
    } finally {
        // do nothing
    }
}
