import React, { useCallback } from 'react';
import T from 'prop-types';
import { connect } from 'react-redux';
import { compose, branch, renderComponent } from 'recompose';
import ErrorBoundary from '@splunk/dashboard-ui/ErrorBoundary';
import Message from '@splunk/dashboard-ui/Message';
import DashboardLayout from '../components/DashboardLayout';
import { selectLayout } from '../state/redux/definition';
import LayoutContainer from './layouts/LayoutContainer';
import GlobalInputContainer from './layouts/GlobalInputContainer';

const mapStateToProps = state => ({
    message: state.message,
    globalInputs: selectLayout(state).globalInputs,
});

const renderErrorMessage = errorMessage => <Message level="error" message={errorMessage} />;

const renderGlobalInput = () => <GlobalInputContainer />;

function Dashboard({ height, width, showGrid, actionMenus, globalInputs }) {
    const renderLayout = useCallback(
        dimension => (
            <LayoutContainer
                width={dimension.width}
                height={dimension.height}
                showGrid={showGrid}
                actionMenus={actionMenus}
            />
        ),
        [showGrid, actionMenus]
    );

    return (
        <ErrorBoundary render={renderErrorMessage}>
            <DashboardLayout
                width={width}
                height={height}
                renderGlobalInput={renderGlobalInput}
                renderLayout={renderLayout}
                showGlobalInputs={globalInputs.length > 0}
            />
        </ErrorBoundary>
    );
}

Dashboard.propTypes = {
    height: T.oneOfType([T.string, T.number]),
    width: T.oneOfType([T.string, T.number]),
    actionMenus: T.arrayOf(T.object),
    showGrid: T.bool,
    globalInputs: T.array,
};

Dashboard.defaultProps = {
    globalInputs: [],
};

const enhancer = compose(
    connect(mapStateToProps),
    branch(
        ({ message }) => message != null,
        renderComponent(({ message }) => <Message level="error" message={message} />)
    )
);

export default enhancer(Dashboard);
