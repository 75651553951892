import each from 'lodash/each';
import get from 'lodash/get';

/**
 * compute new input state given the new input definition, for input has no changes, this is a noop.
 * removed input will be removed from state.
 * newly added input will be add to state with initial value computed.
 * @param {*} currentInputState
 * @param {*} inputDefs
 */
export const createInputState = (currentInputState, inputDefs) => {
    const newState = {};
    each(inputDefs, (inputDef, k) => {
        let inputValue = get(currentInputState, [k, 'value'], '');
        const { defaultValue } = inputDef.options || {};
        if (!inputValue && defaultValue) {
            inputValue = defaultValue;
        }
        newState[k] = {
            value: inputValue,
        };
    });
    return newState;
};

/**
 * Compute the new state given a new value triggered from input
 * @method createNextState
 * @param {*} currentState
 * @param {Mixed} [value='']
 * @param {Object} [inputDef={}]
 * @param {Object} [inputDef.options={}]
 */
export const createNextState = ({ value = '', inputDef: { options = {} } }) => {
    let nextvalue = value;
    if (!nextvalue && options.defaultValue != null) {
        nextvalue = options.defaultValue;
    }
    return {
        value: nextvalue,
    };
};

export const isInputEmpty = value => value == null || value === '';
