export default class EventListener {
    constructor() {
        this.events = {};
    }

    // Simple and Generic PubSub (not keyboard specific)
    subscribe(eventName, callback) {
        if (!eventName || !callback || typeof eventName !== 'string' || typeof callback !== 'function') {
            throw new TypeError('wrong parameters given to subscriber');
        }
        this.events[eventName] = this.getSubscribers(eventName);
        this.events[eventName].push(callback);

        return () => (this.events[eventName] = this.getSubscribers(eventName).filter(cb => cb !== callback));
    }

    subscribeOnce(eventName, callback) {
        const unsubscribe = this.subscribe(eventName, (...args) => {
            unsubscribe();
            callback(...args);
        });
        return unsubscribe;
    }

    publish(eventName, ...data) {
        this.getSubscribers(eventName).forEach(cb => cb(...data));
    }

    unsubscribeAll() {
        this.events = {};
    }

    getSubscribers(eventName) {
        return this.events[eventName] || [];
    }

    hasSubscribers(eventName) {
        return this.getSubscribers(eventName).length > 0;
    }
}
