import isEmpty from 'lodash/isEmpty';
import React, { useMemo } from 'react';
import T from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';
import InputLayout from '@splunk/dashboard-layouts/InputLayout';
import { selectLayout } from '../../state/redux/definition';
import { selectStashedTokens, selectSubmittedTokens, submitTokens } from '../../state/redux/tokens';
import InputContainer from '../InputContainer';
import { contains } from '../../utils/token';

const shouldSubmitButtonDisable = state => {
    // global submit button deal with 'default' namespace
    const stashed = selectStashedTokens(state).default || {};
    const submitted = selectSubmittedTokens(state).default || {};
    // disable submit button when
    // 1. no stashed token
    // 2. stashed tokens are the same as submitted tokens, (submit is a noop in this case)
    return isEmpty(stashed) || contains(submitted, stashed);
};

const mapStateToProps = state => ({
    layout: selectLayout(state),
    submitButtonDisabled: shouldSubmitButtonDisable(state),
});

const mapDispatchToProps = dispatch => ({
    onSubmitButtonClick: () => {
        dispatch(submitTokens('default')); // submit tokens to default namespace
    },
});

const renderInput = id => <InputContainer key={id} id={id} />;

const GlobalInputContainer = ({ layout, submitButtonDisabled, onSubmitButtonClick }) => {
    const submitButton = useMemo(() => !!get(layout, 'options.submitButton'), [layout]);
    const globalInputs = useMemo(() => get(layout, 'globalInputs', []), [layout]);

    return (
        <InputLayout
            submitButtonDisabled={submitButtonDisabled}
            onSubmitButtonClick={onSubmitButtonClick}
            submitButton={submitButton}
            inputStructure={globalInputs}
            renderInput={renderInput}
        />
    );
};

GlobalInputContainer.propTypes = {
    layout: T.object,
    onSubmitButtonClick: T.func,
    submitButtonDisabled: T.bool,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GlobalInputContainer);
