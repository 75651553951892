import map from 'lodash/map';
import React, { PureComponent } from 'react';
import T from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Fullscreen from 'react-full-screen';
import { _ } from '@splunk/ui-utils/i18n';
import Message from '@splunk/dashboard-ui/Message';
import { toPx } from '@splunk/dashboard-utils/style';
import console from '@splunk/dashboard-utils/console';
import DataSourceComposer from '../components/DataSourceComposer';
import { selectMode } from '../state/redux/mode';
import { toggleFullscreen, selectFullscreenElement } from '../state/redux/fullscreen';
import {
    selectVisualization,
    updateVizOptions,
    updateVizTitle,
    updateVizDescription,
    selectDataSourcesForViz,
} from '../state/redux/definition';
import { selectSubmittedTokens } from '../state/redux/tokens';
import { triggerEvent } from '../state/sagas/sagaActions';
import { findInitialRequestParams } from '../utils/datasource';
import { replaceTokenForVisualization, replaceTokenForDataSources } from '../utils/token';

const Item = styled.div.attrs(({ width }) => ({
    style: {
        width,
    },
}))`
    width: ${prop => toPx(prop.width)};
    position: relative;
    pointer-events: auto;
`;

const FixSizeItem = styled.div.attrs(({ width, height }) => ({
    style: {
        width,
        height,
    },
}))`
    position: relative;
    flex-direction: column;
    pointer-events: auto;
`;

const mapStateToProps = (__, { id }) => state => ({
    mode: selectMode(state),
    isFullscreen: selectFullscreenElement(state) === id,
    vizDef: replaceTokenForVisualization(selectVisualization(state, id), selectSubmittedTokens(state)),
    dataSourceDefs: replaceTokenForDataSources(
        selectDataSourcesForViz(state, id),
        selectSubmittedTokens(state)
    ),
});

const mapDispatchToProps = (dispatch, { id }) => ({
    handleEvent: (eventType, payload, eventId) => {
        dispatch(triggerEvent(id, eventType, payload, eventId));
    },
    onOptionsChange: newOptions => {
        dispatch(updateVizOptions(id, newOptions));
    },
    onTitleChange: newTitle => {
        dispatch(updateVizTitle(id, newTitle));
    },
    onDescriptionChange: newDescription => {
        dispatch(updateVizDescription(id, newDescription));
    },
    onExitFullscreen: () => {
        dispatch(toggleFullscreen(null));
    },
});

/**
 * Visualization wrapper that renders the actual visualization and wire up its event and data
 */
class VisualizationContainer extends PureComponent {
    constructor(props, context) {
        super(props);
        this.apiRegistry = context.apiRegistry;
        this.state = {
            error: null,
        };
    }

    componentWillUnmount() {
        this.apiRegistry.removeVisualizationApi(this.props.id);
    }

    registerApi = ref => {
        this.apiRegistry.registerVisualizationApi(this.props.id, ref);
    };

    handleToggleFullscreen = isFull => {
        const { isFullscreen, onExitFullscreen } = this.props;
        if (isFullscreen && !isFull) {
            onExitFullscreen();
        }
    };

    componentDidCatch(error, info) {
        // Display fallback UI
        this.setState({ error: error.message });
        // You can also log the error to an error reporting service
        console.error(error);
        console.warn(`Caught layout item error, stack ${info.componentStack}`);
    }

    renderContent({ width: contentWidth, height: contentHeight }) {
        const {
            id,
            mode,
            vizDef: {
                title,
                description,
                type,
                options = {},
                encoding = {},
                eventHandlers,
                dataSources: dataSourceBindings = {},
            },
            handleEvent,
            onOptionsChange,
            onTitleChange,
            onDescriptionChange,
            onSelected,
            dataSourceDefs,
            isFullscreen,
        } = this.props;
        const { preset, eventRegistry } = this.context;

        const bindings = map(dataSourceBindings, (dataSourceId, bindingType) => {
            return {
                bindingType,
                dataSourceId,
                dataSource: dataSourceDefs[dataSourceId],
                initialRequestParams: findInitialRequestParams({
                    bindingType,
                    consumerModule: preset.findVisualization(type),
                    options,
                }),
            };
        });

        return (
            <Fullscreen enabled={isFullscreen} onChange={this.handleToggleFullscreen}>
                <DataSourceComposer consumerId={id} bindings={bindings}>
                    {({ loading, dataSources, updateRequestParams }) => {
                        const vizProps = {
                            id,
                            mode,
                            width: isFullscreen ? '100%' : contentWidth,
                            height: isFullscreen ? '100%' : contentHeight,
                            title,
                            description,
                            options,
                            encoding,
                            dataSources,
                            loading,
                            hasEventHandlers: eventHandlers && eventHandlers.length > 0,
                            onEventTrigger: ({ type: eventType, originalEvent, payload }) => {
                                const eventId = eventRegistry.registerEvent(originalEvent);
                                handleEvent(eventType, payload, eventId);
                            },
                            visualizationApiRef: this.registerApi,
                            onRequestParamsChange: updateRequestParams,
                            onOptionsChange,
                            onSelected,
                            onTitleChange,
                            onDescriptionChange,
                        };
                        return preset.createVisualization(type, vizProps);
                    }}
                </DataSourceComposer>
            </Fullscreen>
        );
    }

    render() {
        const { id, width, height, vizDef = {} } = this.props;
        const { error } = this.state;

        return height != null ? (
            <FixSizeItem
                data-test="viz-item"
                data-viz-type={vizDef.type}
                data-id={id}
                width={width}
                height={height}
            >
                {error ? <Message level="error" message={_(error)} /> : this.renderContent({ width, height })}
            </FixSizeItem>
        ) : (
            <Item data-test="viz-item" data-viz-type={vizDef.type} data-id={id} width={width}>
                {error ? <Message level="error" message={_(error)} /> : this.renderContent({ width })}
            </Item>
        );
    }
}

VisualizationContainer.propTypes = {
    mode: T.string,
    id: T.string,
    width: T.number,
    height: T.number,
    vizDef: T.object,
    dataSourceDefs: T.object,
    handleEvent: T.func,
    onOptionsChange: T.func,
    onTitleChange: T.func,
    onDescriptionChange: T.func,
    onSelected: T.func,
    isFullscreen: T.bool,
    onExitFullscreen: T.func,
};

VisualizationContainer.contextTypes = {
    preset: T.object,
    eventRegistry: T.object,
    apiRegistry: T.object,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(VisualizationContainer);
