/* eslint-disable no-unused-vars */
import memoizeOne from 'memoize-one';
import each from 'lodash/each';
import pick from 'lodash/pick';
import keys from 'lodash/keys';
import { _ } from '@splunk/ui-utils/i18n';
import isEqual from '@splunk/dashboard-utils/jsonEqual';
import { replaceTokens, replaceTokensForObject, hasTokensInObject } from '@splunk/dashboard-utils/token';

export const replaceTokenForDataSources = memoizeOne((dataSourceDefs, tokens = {}) => {
    const resolved = {};
    each(dataSourceDefs, (def, id) => {
        const resolvedOptions = replaceTokensForObject(dataSourceDefs[id].options, tokens);
        if (hasTokensInObject(resolvedOptions)) {
            resolved[id] = {
                type: '_ds.snapshot_',
                options: {
                    errorLevel: 'info',
                    error: _('Waiting for input...'),
                },
            };
        } else {
            resolved[id] = {
                ...dataSourceDefs[id],
                options: resolvedOptions,
            };
        }
    });
    return resolved;
});

export const replaceTokenForInput = memoizeOne((inputDef, tokens = {}) => ({
    ...inputDef,
    title: replaceTokens(inputDef.title, tokens),
    options: replaceTokensForObject(inputDef.options, tokens),
}));

export const replaceTokenForInputs = memoizeOne((inputDefs, tokens = {}) => {
    const resolved = {};
    each(inputDefs, (def, id) => {
        resolved[id] = replaceTokenForInput(def, tokens);
    });
    return resolved;
});

export const replaceTokenForVisualization = memoizeOne((vizDef, tokens = {}) => ({
    ...vizDef,
    title: replaceTokens(vizDef.title, tokens),
    description: replaceTokens(vizDef.description, tokens),
    options: replaceTokensForObject(vizDef.options, tokens),
}));

export const replaceTokenForVisualizations = memoizeOne((vizDefs, tokens = {}) => {
    const resolved = {};
    each(vizDefs, (def, id) => {
        resolved[id] = replaceTokenForVisualization(def, tokens);
    });
    return resolved;
});

export const replaceTokenForLayout = memoizeOne((layout = {}, tokens = {}) => {
    return {
        ...layout,
        options: replaceTokensForObject(layout.options, tokens),
    };
});

export const contains = (tokens = {}, subset = {}) => isEqual(subset, pick(tokens, keys(subset)));
