import React from 'react';
import T from 'prop-types';
import { _ } from '@splunk/ui-utils/i18n';

/**
 * Generic ErrorBoundary class that could be used to wrap any
 * component. In case the weapped component or any of its child
 * throws an error, Error bountry would catch it. It also exposes
 * render function ( render props ) that allows or custom UI to
 * be rendered in case of error.
 */
class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: false,
        };
    }

    componentDidCatch(error) {
        if (this.props.useMessageFromError && error && error.message) {
            this.setState({
                error: true,
                errorMessage: error.message,
            });
        } else {
            this.setState({
                error: true,
            });
        }
    }

    render() {
        const { children, errorMessage } = this.props;
        if (this.state.error) {
            return this.props.render(this.state.errorMessage || errorMessage);
        }
        return children;
    }
}

ErrorBoundary.defaultProps = {
    errorMessage: _('failed to render react component'),
    render: errorMessage => <div>{errorMessage}</div>,
    useMessageFromError: true,
};

ErrorBoundary.propTypes = {
    /** Allow nodes to be added as child to the error boundary */
    children: T.node,
    /** Allows passing in a custom error message */
    errorMessage: T.string,
    /** Render function (renderprops pattern) to allow Custom UI rendered on error */
    render: T.func,
    /** In case of error , use the message from "error.message" if set to true */
    useMessageFromError: T.bool,
};

export default ErrorBoundary;
