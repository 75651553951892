import defaultsDeep from 'lodash/defaultsDeep';
import transform from 'lodash/transform';
import get from 'lodash/get';
import React from 'react';
import { _ } from '@splunk/ui-utils/i18n';
import SnapshotDataSource from '@splunk/datasources/SnapshotDataSource';
import Message from '@splunk/dashboard-ui/Message';

export const defToArray = items =>
    transform(
        items,
        (ret, v, k) => {
            ret.push({
                type: k,
                ...v,
            });
        },
        []
    );

const noopHandler = {
    canHandle: () => false,
};

export default class Preset {
    /* eslint-disable-next-line class-methods-use-this */
    getDefaultPreset() {
        return {
            visualizations: {},
            inputs: {},
            dataSources: {
                '_ds.snapshot_': SnapshotDataSource,
            },
            eventHandlers: {},
            layouts: {},
        };
    }

    normalize(preset) {
        return defaultsDeep(preset, this.getDefaultPreset());
    }

    constructor(presetDefinition = {}) {
        this.presetDef = this.normalize(presetDefinition);
    }

    listLayouts() {
        return defToArray(this.presetDef.layouts);
    }

    listVisualizations() {
        return defToArray(this.presetDef.visualizations);
    }

    listDataSources() {
        return defToArray(this.presetDef.dataSources);
    }

    listInputs() {
        return defToArray(this.presetDef.inputs);
    }

    listEventHandlers() {
        return defToArray(this.presetDef.eventHandlers);
    }

    findVisualization(type) {
        return get(this.presetDef, ['visualizations', type]);
    }

    findDataSource(type) {
        return get(this.presetDef, ['dataSources', type]);
    }

    findInput(type) {
        return get(this.presetDef, ['inputs', type]);
    }

    findEventHandler(type) {
        return get(this.presetDef, ['eventHandlers', type]);
    }

    findLayout(type) {
        return get(this.presetDef, ['layouts', type]);
    }

    /**
     * create visualization component
     * @param {*} type visualization type
     * @param {*} props computed react props
     */
    createVisualization(type, props) {
        const Viz = this.findVisualization(type);
        try {
            if (Viz == null) {
                return React.createElement(Message, { level: 'error', message: _(`${type} is not defined`) });
            }
            return React.createElement(Viz, { ...props, type });
        } catch (ex) {
            return React.createElement(Message, { level: 'error', message: ex.message });
        }
    }

    /**
     * instantiate visualization editor component
     * @param  {...any} args
     */
    createVisualizationEditor(...args) {
        return this.createVisualization(...args);
    }

    /**
     * instantiate datasource
     * @param {*} type datasource type
     * @param {*} options datasource options
     * @param {*} dataSourceContext  datasource context
     * @param {*} meta metadata
     * @param {*} id datasource id
     */
    createDataSource(type, options, dataSourceContext, meta, id) {
        try {
            const DataSourceDef = this.findDataSource(type);
            if (DataSourceDef) {
                // pass id within context for testing purporse
                return new DataSourceDef(options, { ...dataSourceContext, id }, meta);
            }
            return new SnapshotDataSource({ errorLevel: 'error', error: _(`${type} is not defined`) });
        } catch (ex) {
            return new SnapshotDataSource({ errorLevel: 'error', error: ex.message });
        }
    }

    /**
     * instantiate input component
     * @param {*} type
     * @param {*} props
     */
    createInput(type, props) {
        const Input = this.findInput(type);
        try {
            if (Input == null) {
                return null;
            }
            return React.createElement(Input, { ...props, type });
        } catch (ex) {
            // todo render a placeholder input?
            return null;
        }
    }

    /**
     * instantiate event handlers
     * @param {*} type
     * @param {*} options
     */
    createEventHandler(type, options) {
        try {
            const EventHandlerDef = this.findEventHandler(type);
            if (EventHandlerDef) {
                return new EventHandlerDef(options);
            }
            return noopHandler;
        } catch (ex) {
            return noopHandler;
        }
    }

    /**
     * instantiate layout component
     * @param {*} type
     * @param {*} props
     */
    createLayout(type, props) {
        const Layout = this.findLayout(type);
        try {
            if (Layout == null) {
                return React.createElement(Message, { level: 'error', message: _(`${type} is not defined`) });
            }
            return React.createElement(Layout, { ...props, type });
        } catch (ex) {
            return React.createElement(Message, { level: 'error', message: ex.message });
        }
    }

    /**
     * instantiate layout editor component
     * @param  {...any} args
     */
    createLayoutEditor(...args) {
        return this.createLayout(...args);
    }
}
