/* eslint-disable react/no-unused-prop-types,class-methods-use-this */
import get from 'lodash/get';
import React, { PureComponent } from 'react';
import T from 'prop-types';
import DashboardContext from '@splunk/dashboard-context';
import DashboardDefinition from '@splunk/dashboard-definition/DashboardDefinition';
import { createSchemaBasedOnPresets } from '@splunk/dashboard-definition/DashboardSchema';
import Preset from './preset/Preset';
import createSaga from './state/sagas';
import createStore, { resetStore } from './state/redux';
import EventRegistry from './events/EventRegistry';
import ApiRegistry from './extensions/ApiRegistry';
import DataSourceRegistry from './datasources/DataSourceRegistry';
import DashboardProvider from './containers/DashboardProvider';
import DashboardContainer from './containers/DashboardContainer';
import { createDashboardPlugin } from './extensions/DashboardCorePlugin';
import { createDashboardApi } from './extensions/DashboardCoreApi';
import {
    initialize,
    teardown,
    submittedTokenChanged,
    definitionChanged,
    selectedVisualizationChanged,
} from './state/sagas/sagaActions';
import dashboardCoreThemes from './styles/themes';

const formatValidateErrors = errors => errors.map(({ dataPath, message }) => `${dataPath} ${message}`).join();

// expose themes
export const themes = dashboardCoreThemes;
export default class DashboardCore extends PureComponent {
    static contextType = DashboardContext;

    static propTypes = {
        /**
         *  The dashboard definition json object
         */
        definition: T.object,

        /**
         * Dashboard Mode. ('view' or 'edit')
         */
        mode: T.string,

        /**
         * Callback to signal definition change
         */
        onDefinitionChange: T.func,

        /**
         * selected visualization array in edit mode
         */
        selectedVisualizations: T.arrayOf(T.string),

        /**
         * Callback when visualizations been selected in edit mode
         */
        onVisualizationsSelect: T.func,

        /**
         *  datasource context
         */
        dataSourceContext: T.object,

        /**
         * Supply a custom token binding for the dashboard. If this is provided, and
         * every time it changes, the token binding of the dashboard is reset.
         */
        tokenBinding: T.object,

        /**
         * A callback for when the tokenBinding change
         */
        onTokenBindingChange: T.func,

        /**
         * Optionally add a dashboard lifecycle plugin which allows to hook
         * into certain lifecycle events of the dashboard by providing
         * callback functions.
         */
        dashboardCorePlugin: T.shape({
            /**
             * Called when the dashboard is initialized
             */
            onInitialize: T.func,
            /**
             * Called when an event triggered
             */
            onEventTrigger: T.func,
            /**
             * Called when navigation will occur
             */
            onLinkToUrl: T.func,
        }),

        /**
         * Hook to retrieve a reference to the dashboard lifecycle API, which
         * allows programmatic interaction with the dashboard.
         */
        dashboardCoreApiRef: T.func,

        /**
         * Dashboard Preset
         */
        preset: T.shape({
            layouts: T.objectOf(T.elementType),
            visualizations: T.objectOf(T.elementType),
            dataSources: T.objectOf(T.elementType),
            inputs: T.objectOf(T.elementType),
            eventHandlers: T.objectOf(T.func),
        }),
        /**
         * width in pixel or string, defaults to 100%
         */
        width: T.oneOfType([T.string, T.number]),

        /**
         * height in pixel or string, defaults to 600px
         */
        height: T.oneOfType([T.string, T.number]),
        /**
         * viz action menus
         */
        actionMenus: T.arrayOf(T.element),
        /**
         * metadata for logging purposes
         */
        metadata: T.object,
        /**
         * whether gridlines should be displayed in edit mode
         */
        showGrid: T.bool,
    };

    static defaultProps = {
        mode: 'view',
        showGrid: true,
        definition: {},
        width: '100%',
        height: 600, // default height to 600px
        actionMenus: [],
        selectedVisualizations: [],
        dashboardCoreApiRef: () => {},
        onDefinitionChange: () => {},
        onVisualizationsSelect: () => {},
        onTokenBindingChange: () => {},
    };
    // reference DashboardContext

    constructor(props, ...args) {
        super(props, ...args);
        // instantiate event registry and preset
        this.eventRegistry = new EventRegistry();
        this.apiRegistry = new ApiRegistry();
        this.preset = new Preset(props.preset);
        this.dataSourceRegistry = new DataSourceRegistry({
            preset: this.preset,
            dataSourceContext: props.dataSourceContext || get(this.context, 'dataSourceContext', {}),
        });
        this.dashboardPlugin = createDashboardPlugin(props.dashboardCorePlugin);
        // bootstrap store and saga
        const rootSaga = createSaga({
            dashboardPlugin: this.dashboardPlugin,
            preset: this.preset,
            eventRegistry: this.eventRegistry,
            apiRegistry: this.apiRegistry,
            dataSourceRegistry: this.dataSourceRegistry,
        });
        this.store = createStore({
            initialState: this.getInitialStoreState(props),
            actionListenerDefs: this.getActionListeners(),
            rootSaga,
        });
        this.dashboardCoreApi = createDashboardApi({
            store: this.store,
            apiRegistry: this.apiRegistry,
            dataSourceRegistry: this.dataSourceRegistry,
        });
        // kickoff the data source update loop
        this.store.dispatch(initialize());
    }

    componentDidMount() {
        // passing out the dashboard api so that consumer can use it to control dashboard core.
        this.props.dashboardCoreApiRef(this.dashboardCoreApi);
        this.dashboardPlugin.invokePluginCallback('onInitialize', {});
    }

    componentDidUpdate(prevProps) {
        const didDefinitionChange = this.props.definition !== prevProps.definition;
        const newStore = {
            ...this.store.getState(),
            ...this.getInitialStoreState(this.props, didDefinitionChange),
        };
        this.store.dispatch(resetStore(newStore));
    }

    componentWillUnmount() {
        this.apiRegistry.teardown();
        this.eventRegistry.teardown();
        // singal saga to be teardown
        this.store.dispatch(teardown());
        // remove the reference to dashboardApi
        this.props.dashboardCoreApiRef(null);
    }

    getInitialStoreState(props, didDefinitionChange = true) {
        const definition = DashboardDefinition.fromJSON(props.definition);
        const logger = get(this.context, 'logger');
        const state = {
            mode: props.mode,
            definition: definition.toJSON(),
            tokens: {
                submitted: props.tokenBinding || {},
            },
            editor: {
                selectedVisualizations: props.selectedVisualizations,
            },
        };
        const schema = createSchemaBasedOnPresets(props.preset);
        const setSchemaErrors = definition.setSchema(schema);
        if (setSchemaErrors) {
            if (didDefinitionChange) {
                if (props.onValidationError) {
                    props.onValidationError(setSchemaErrors.message);
                } else {
                    window.console.error(setSchemaErrors.message);
                }
            }
            if (logger && typeof logger.error === 'function') {
                logger.error({ message: setSchemaErrors.message, metadata: props.metadata });
            }
            return state;
        }

        const validationErrors = definition.validate();
        if (validationErrors) {
            const formattedError = formatValidateErrors(validationErrors);
            if (didDefinitionChange) {
                if (props.onValidationError) {
                    props.onValidationError(formattedError);
                } else {
                    window.console.error(formattedError);
                }
            }
            if (logger && typeof logger.error === 'function') {
                logger.error({ message: formattedError, metadata: props.metadata });
            }
        }
        return state;
    }

    getActionListeners() {
        return {
            [submittedTokenChanged]: () => {
                const { tokens = {} } = this.store.getState();
                this.props.onTokenBindingChange(tokens.submitted || {});
            },
            [definitionChanged]: () => {
                const { definition } = this.store.getState();
                this.props.onDefinitionChange(definition);
            },
            [selectedVisualizationChanged]: () => {
                const { editor = {} } = this.store.getState();
                this.props.onVisualizationsSelect(editor.selectedVisualizations);
            },
        };
    }

    /**
     * inject dashboard api if it's missing
     */
    cloneActionMenus = menus =>
        menus.map(item => {
            if (item.props.dashboardApi == null) {
                return React.cloneElement(item, {
                    dashboardApi: this.dashboardCoreApi,
                });
            }
            return item;
        });

    render() {
        const { width, height, actionMenus, showGrid } = this.props;
        return (
            <DashboardProvider
                preset={this.preset}
                store={this.store}
                eventRegistry={this.eventRegistry}
                apiRegistry={this.apiRegistry}
                dataSourceRegistry={this.dataSourceRegistry}
            >
                <DashboardContainer
                    width={width}
                    height={height}
                    showGrid={showGrid}
                    actionMenus={this.cloneActionMenus(actionMenus)}
                />
            </DashboardProvider>
        );
    }
}
