import React from 'react';
import T from 'prop-types';
import styled from 'styled-components';
import { createThemeRegistry } from '@splunk/dashboard-themes/helper';
import { enterprise, enterpriseDark, scp } from '@splunk/dashboard-themes/variables';
import SubmitButton from './components/Submit';

const themeRegistry = createThemeRegistry('@splunk/dashboard-layouts', 'InputLayout');

const InputContainer = styled.div`
    ${themeRegistry.mixin('reset')('block')};
    padding: ${prop => (prop.inputStructure.length > 0 ? 8 : 0)}px;
    background: ${prop => prop.backgroundColor || themeRegistry.themeVariable('backgroundColor')(prop)};
`;

/**
 * Simple Input Layout
 * @param {*} param0
 */
const InputLayout = ({
    submitButton,
    submitButtonDisabled,
    inputStructure,
    renderInput,
    onSubmitButtonClick,
}) => {
    const submit =
        inputStructure.length && submitButton ? (
            <SubmitButton key="submit_button" disabled={submitButtonDisabled} onClick={onSubmitButtonClick} />
        ) : null;
    return (
        <InputContainer data-test="input-container" inputStructure={inputStructure}>
            {inputStructure.map(inputId => renderInput(inputId))}
            {submit}
        </InputContainer>
    );
};

InputLayout.propTypes = {
    /**
     * whether disaible the submit button
     */
    submitButtonDisabled: T.bool,
    /**
     * whether to display submit button
     */
    submitButton: T.bool,
    /**
     * input id array
     */
    inputStructure: T.arrayOf(T.string),
    /**
     * input component instances
     */
    renderInput: T.func,
    /**
     * callback when user clicks submit button
     */
    onSubmitButtonClick: T.func,
};

InputLayout.defaultProps = {
    submitButtonDisabled: false,
    submitButton: false,
    inputStructure: [],
    onSubmitButtonClick: () => {},
    renderInput: () => null,
};

themeRegistry.addDefaultTheme('enterprise', {
    backgroundColor: enterprise.dashboardBackgroundColor,
});

themeRegistry.addTheme('enterpriseDark', {
    backgroundColor: enterpriseDark.dashboardBackgroundColor,
});

themeRegistry.addTheme('scp', {
    backgroundColor: scp.dashboardBackgroundColor,
});

export const themes = themeRegistry.toThemes();

export default InputLayout;
