const hasWindowConsole = 'console' in window;
const hasConsoleMethod = method => hasWindowConsole && typeof window.console[method] === 'function';
const noop = () => {};

const console = {
    log: hasConsoleMethod('log') ? (...args) => window.console.log(...args) : noop,
    debug: hasConsoleMethod('debug') ? (...args) => window.console.debug(...args) : noop,
    info: hasConsoleMethod('info') ? (...args) => window.console.info(...args) : noop,
    warn: hasConsoleMethod('warn') ? (...args) => window.console.warn(...args) : noop,
    error: hasConsoleMethod('error') ? (...args) => window.console.error(...args) : noop,
    group: hasConsoleMethod('group') ? (...args) => window.console.group(...args) : noop,
    groupCollapsed: hasConsoleMethod('groupCollapsed')
        ? (...args) => window.console.groupCollapsed(...args)
        : noop,
    groupEnd: hasConsoleMethod('groupEnd') ? (...args) => window.console.groupEnd(...args) : noop,

    logWithDetails(message, lines) {
        console.groupCollapsed(message);
        lines.forEach(line => (Array.isArray(line) ? console.log(...line) : console.log(line)));
        console.groupEnd();
    },
};

export default console;

export function logfmt(strings, ...values) {
    return [strings.join('%o'), ...(values || [])];
}
