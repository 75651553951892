import React, { PureComponent } from 'react';
import T from 'prop-types';
import { connect } from 'react-redux';
import { selectMode } from '../state/redux/mode';
import DataSourceConsumer from '../components/DataSourceConsumer';
import { selectVisualization, updateVizOptions, selectDataSourcesForViz } from '../state/redux/definition';
import { selectSubmittedTokens } from '../state/redux/tokens';
import { triggerEvent } from '../state/sagas/sagaActions';
import { replaceTokenForVisualization, replaceTokenForDataSources } from '../utils/token';
import { findInitialRequestParams } from '../utils/datasource';

const mapStateToProps = (__, { id }) => state => ({
    mode: selectMode(state),
    context: state.context,
    vizDef: replaceTokenForVisualization(selectVisualization(state, id), selectSubmittedTokens(state)),
    dataSourceDefs: replaceTokenForDataSources(
        selectDataSourcesForViz(state, id),
        selectSubmittedTokens(state)
    ),
});

const mapDispatchToProps = (dispatch, { id }) => ({
    handleEvent: (eventType, payload, eventId) => {
        dispatch(triggerEvent(id, eventType, payload, eventId));
    },
    onOptionsChange: newOptions => {
        dispatch(updateVizOptions(id, newOptions));
    },
});

/**
 * Connection line container
 */
class LineContainer extends PureComponent {
    renderLine({ loading, dataSources, onRequestParamsChange }) {
        const {
            id,
            mode,
            from,
            to,
            onLineSelect,
            context,
            vizDef: { type, options = {}, encoding = {} },
            handleEvent,
            onOptionsChange,
        } = this.props;
        const { preset, eventRegistry } = this.context;
        const lineProps = {
            id,
            mode,
            from,
            to,
            onLineSelect,
            options,
            encoding,
            dataSources,
            loading,
            onEventTrigger: ({ type: eventType, originalEvent, payload }) => {
                const eventId = eventRegistry.registerEvent(originalEvent);
                handleEvent(eventType, payload, eventId);
            },
            onOptionsChange,
            onRequestParamsChange,
        };
        return preset.createVisualization(type, lineProps, context);
    }

    render() {
        const {
            id,
            vizDef: { type, options = {}, dataSources: dataSourceBindings = {} },
            dataSourceDefs,
        } = this.props;
        const { preset } = this.context;
        // line only support primary DataSource
        const primaryDataSourceId = dataSourceBindings.primary;
        let primaryDataSource;
        if (primaryDataSourceId) {
            primaryDataSource = dataSourceDefs[primaryDataSourceId];
        }
        return primaryDataSource ? (
            <DataSourceConsumer
                consumerId={id}
                initialRequestParams={findInitialRequestParams({
                    bindingType: 'primary',
                    consumerModule: preset.findVisualization(type),
                    options,
                })}
                bindingType="primary"
                dataSource={primaryDataSource}
                dataSourceId={primaryDataSourceId}
            >
                {({ loading, requestParams, updateRequestParams, data, meta, error }) => {
                    return this.renderLine({
                        loading,
                        dataSources: {
                            primary: {
                                requestParams,
                                data,
                                meta,
                                error,
                            },
                        },
                        onRequestParamsChange: (bindingType, newRequestParams) => {
                            updateRequestParams(newRequestParams);
                        },
                    });
                }}
            </DataSourceConsumer>
        ) : (
            this.renderLine({
                loading: false,
                dataSources: {},
                onRequestParamsChange: () => {},
            })
        );
    }
}

LineContainer.propTypes = {
    mode: T.string,
    id: T.string,
    from: T.shape({
        x: T.number,
        y: T.number,
    }),
    to: T.shape({
        x: T.number,
        y: T.number,
    }),
    context: T.object,
    vizDef: T.object,
    dataSourceDefs: T.object,
    handleEvent: T.func,
    onLineSelect: T.func,
    onOptionsChange: T.func,
};

LineContainer.contextTypes = {
    preset: T.object,
    eventRegistry: T.object,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LineContainer);
