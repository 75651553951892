import { createAction, handleActions } from 'redux-actions';

/**
 * Actions
 */
export const switchMode = createAction('SWITCH_MODE');

/**
 *
 */
export const selectMode = state => state.mode;

/**
 * Reducer
 */
export default handleActions(
    {
        [switchMode]: (state, { payload }) => payload,
    },
    'view'
);
