import find from 'lodash/find';
import has from 'lodash/has';
import isString from 'lodash/isString';
import isFinite from 'lodash/isFinite';
import { sprintf } from '@splunk/ui-utils/format';
import { _ } from '@splunk/ui-utils/i18n';
import { locale } from '@splunk/splunk-utils/config';
import moment from '@splunk/moment';
import { timeUnits, snapUnits } from './timeUnits';

const momentUnits = {
    s: 's',
    m: 'm',
    h: 'h',
    d: 'd',
    w: 'w',
    mon: 'M',
    q: 'Q',
    y: 'y',
};

const momentSnaps = {
    s: 'second',
    m: 'minute',
    h: 'hour',
    d: 'day',
    w: 'week',
    mon: 'month',
    q: 'quarter',
    y: 'year',
};

const weekDaySnaps = {
    w0: 0,
    w1: 1,
    w2: 2,
    w3: 3,
    w4: 4,
    w5: 5,
    w6: 6,
    w7: 0,
};

/**
 * Returns a moment for the given time or the current time, if none is given
 * @method epochToMoment
 * @param {Number} [epoch] given time in seconds
 * @returns Moment
 */
export const epochToMoment = epoch => {
    // If we get an input time, convert to ms, otherwise generate current time
    const time = epoch || epoch === 0 ? epoch * 1000 : new Date().getTime();

    if (moment.getDefaultSplunkTimezone()) {
        return moment.newSplunkTime({ time });
    }

    // fallback to moment default
    return moment(time);
};

/**
 * Normalizes units to it's shortest version, such as `s` for `sec` and `mon` for `month`.
 * @name normalizeUnit
 * @function
 * @public
 * @param {string} unit - The unit, such as `s` or `quarter`.
 * @param {bool} [removeInvalid=true] - When true, returns an empty string for invalid units,
 * when false
 * returns 's' for invalid units.
 * @returns {string} Returns the normalized unit or empty string.
 */
export function normalizeUnit(abbr, removeInvalid = true) {
    const hasUnit = has(timeUnits, abbr);
    const defaultUnit = removeInvalid ? '' : timeUnits.s.abbr;
    return hasUnit ? timeUnits[abbr].abbr : defaultUnit;
}
/**
 * Normalizes snap units to it's shortest version, this is the same as normalizeUnit, but also
 * supports weekdays, such as `w5`.
 * @public
 * @param {string} unit - The unit, such as `s`, `quarter` or `w0`.
 * @param {bool} [removeInvalid=true] - When true, returns an empty string for invalid units,
 * when false returns 's' for invalid units.
 * @returns {string} Returns the normalized unit or empty string.
 */
export function normalizeSnapUnit(abbr, removeInvalid = true) {
    const hasUnit = has(snapUnits, abbr);
    const defaultUnit = removeInvalid ? '' : timeUnits.s.abbr;
    return hasUnit ? snapUnits[abbr].abbr : defaultUnit;
}

/**
 * Returns a label for a unit abbreviation, such as 'second' for 's' or 'sec'.
 * @public
 * @param {object} unit -  The unit, such as `s`, `quarter` or `w0`.
 * @param {object} [plural=false] - Whether the returned label should be plural.
 * @returns {string}
 */
export function getUnitLabel(unit, plural = false) {
    return snapUnits[unit][plural ? 'plural' : 'singular'];
}

/**
 * Strips rt from the beginning of a time string when found. This makes a time string compatible
 * with the time parser. To ensure capability with the time parser 'rt' returns 'now'.
 * @public
 * @param {string} time - The time string such as `47165491` or `rt-2h@m`.
 * @returns {string} Returns the time string.
 */
export function removeRealTime(time) {
    if (time === 'rt') {
        return 'now';
    }
    return time.replace(/^rt/, '');
}

/**
 * Removes the timezone from an iso time string
 * @public
 * @param {string} time - The time string such as `47165491` or `-2h@m`.
 * @returns {string}
 */
export function removeISOTimezone(time) {
    return time.replace(/[+-]\d?\d:\d\d$/, '');
}

/**
 * Validates that a string represents a unix epoch time.
 * @public
 * @param {string} time - The time string such as `47165491` or `-2h@m`.
 * @returns {bool}
 */
export function isEpoch(time) {
    return isFinite(time) || (isString(time) && /^\d+((\.\d+)|(\d*))$/.test(time));
}

/**
 * Validates that a string represents an ISO time.
 * @public
 * @param {string} time - The time string such as `47165491` or `-2h@m`.
 * @returns {bool}
 */
export function isISO(time) {
    return !!time.match(/^\d\d\d\d-\d\d?-\d\d?T\d\d?:\d\d?(:\d\d?)?(\.\d\d?\d?)?([+-]\d\d?:\d\d|Z)?$/);
}

/**
 * Validates that a string represents an ISO or unix epoch time.
 * @public
 * @param {string} time - The time string such as `47165491` or `-2h@m`.
 * @returns {bool}
 */
export function isAbsolute(time) {
    if (time === undefined) {
        return false;
    }
    return isEpoch(time) || isISO(time);
}

/**
 * Parses a time string for inspection or form population.
 *
 * Example parse for a relative time string:
 * ```
 * {
 *     string: '-3d@qtr+2hr',
 *     type: ['relative'], // 'relative', 'realTime', 'iso', or 'epoch'
 *     isFullyParsed: true,
 *     modifiers: [
 *         {
 *             string: '-3d@qtr',
 *             isParsed: true,
 *             unit: 'd',
 *             amount: -3,
 *             snap: 'q',
 *         },
 *         {
 *             string: '+2hr',
 *             isParsed: true,
 *             unit: 'h',
 *             amount: +2,
 *             snap: false,
 *         },
 *     ],
 * }
 * ```
 * Example parse for a epoch time:
 * ```
 * {
 *     string: '89451357',
 *     type: ['epoch'],
 *     isFullyParsed: true,
 *     modifiers: [],
 * }
 *
 * @public
 * @param {string} time - The time string such as `47165491` or `-2h@m`.
 * @returns {object}
 */

export function parseTimeString(timeString) {
    if (!isString(timeString)) {
        return false;
    }

    const ast = {
        string: timeString,
        isFullyParsed: true,
        modifiers: [],
    };

    if (isISO(timeString)) {
        ast.type = 'iso';
        return ast;
    }
    if (isEpoch(timeString)) {
        ast.type = 'epoch';
        return ast;
    }
    const segments = timeString
        .trim()
        .replace(/-/g, '\n-')
        .replace(/\+/g, '\n+')
        .split('\n');

    // If only snap has been provided push empty string to beginning of segments.
    if (segments[0].charAt(0) === '@') {
        segments.unshift('');
    }

    if (segments[0] === 'rt' || segments[0] === 'rtnow') {
        ast.type = 'realTime';
    } else if (['rt', 'now', 'rtnow', ''].indexOf(segments[0]) < 0) {
        ast.isFullyParsed = false;
        return ast;
    } else {
        ast.type = 'relative';
    }

    ast.modifiers = segments.slice(1).map(segmentString => {
        const segment = {
            string: segmentString,
            isParsed: false,
        };

        const sParse = segmentString.match(/^(([-+]\d*)([a-zA-Z]*))?(@([a-zA-Z][a-zA-Z0-7]*))?$/);
        //                                          2         3      4          5

        if (sParse) {
            segment.unit = sParse[3] && normalizeUnit(sParse[3], false);
            segment.amount = segment.unit ? parseInt(sParse[2], 10) || 1 : 0;
            segment.snap = !!sParse[4] && normalizeSnapUnit(sParse[5], false);
            segment.isParsed = true;

            if (
                (sParse[5] && !snapUnits[sParse[5]]) || // invalid unit
                (sParse[3] && !timeUnits[sParse[3]]) || // invalid snap unit
                segmentString.length === 1 // unqualified - or +
            ) {
                ast.isFullyParsed = false;
                segment.isParsed = false;
            }
        } else {
            ast.isFullyParsed = false;
        }

        return segment;
    });

    return ast;
}

/**
 * Validates that a string is a valid time string.
 * @public
 * @param {string} time - The time string such as `rt` , `rtnow` or `-2h@m`.
 * @returns {bool}
 */
export function isValidTime(time) {
    return parseTimeString(time).isFullyParsed;
}

/**
 * Validates that a string represents a real-time search.
 * @public
 * @param {string} time - The time string such as `rt` , `rtnow` or `-2h@m`.
 * @returns {bool}
 */
export function isRealTime(time) {
    const parsedTimeString = parseTimeString(time);

    return parsedTimeString && parsedTimeString.type === 'realTime' && parsedTimeString.isFullyParsed;
}

/**
 * Validates that a iso time string is a whole day.
 * @public
 * @param {string|object} time - A time string (such as `2008-09-15T15:53:00+05:00`) or a
 * @splunk/moment time instance.
 * @returns {bool}
 */
export function isWholeDay(time) {
    if ((isString(time) && isISO(time)) || (moment.isMoment(time) && time.isValid())) {
        const timeMoment = moment(time);
        return timeMoment.valueOf() === timeMoment.startOf('day').valueOf();
    }
    return false;
}

/**
 * Validate that a time string acts is either empty or `0`.
 * @public
 * @param {string} time - The time string such as `rt` , `rtnow` or `-2h@m`.
 * @returns {bool}
 */
export function isEarliestEmpty(time) {
    if (time === '0') {
        return true;
    }
    return !time;
}

/**
 * Validate that a time string acts is either empty or now
 * @public
 * @param {string} time - The time string such as `rt` , `rtnow` or `-2h@m`.
 * @returns {bool}
 */
export function isLatestNow(time) {
    if (!time) {
        return true;
    }
    return isString(time) && time === 'now';
}

/**
 * Validate that a time range acts is equivalent to all-time.
 * @public
 * @param {string} earliest - The time string such as `rt` , `rtnow` or `-2h@m`.
 * @param {string} latest - The time string such as `rt` , `rtnow` or `-2h@m`.
 * @returns {bool}
 */
export function isAllTime(earliest, latest) {
    return isEarliestEmpty(removeRealTime(earliest)) && isLatestNow(removeRealTime(latest));
}

/**
 * Validate that two time range are equivalent. This normalizes the two comparisons using
 * isEarliestEmpty() and isLatestNow().
 * @public
 * @param {object} range1 - The time string range  such as `{ earliest: '-1d', latest: 'now' }`.
 * @param {object} range2 -  The time string range such as `{ earliest: '0', latest: '-1d' }`.
 * @returns {bool}
 */
export function timeRangesAreEquivalent(range1, range2) {
    const earliest1 = range1.earliest;
    const latest1 = range1.latest;

    const earliest2 = range2.earliest;
    const latest2 = range2.latest;

    let earliestEqual = false;
    let latestEqual = false;

    if (isEarliestEmpty(earliest1) && isEarliestEmpty(earliest2)) {
        earliestEqual = true;
    } else {
        earliestEqual = earliest1 === earliest2;
    }

    if (isLatestNow(latest1) && isLatestNow(latest2)) {
        latestEqual = true;
    } else {
        latestEqual = latest1 === latest2;
    }

    return earliestEqual && latestEqual;
}
/**
 * Searches through an array of presets and returns any equivalent labels using
 * timeRangesAreEquivalent().
 * @public
 * @param {array} presets - An array of presents such as:
 * ```
 * [
 *     { label: '30 second window', earliest: 'rt-30s', latest: 'rt' },
 *     { label: 'Today', earliest: '@d', latest: 'now' },
 *     { label: 'Previous year', earliest: '-1y@y', latest: '@y' },
 *     { label: 'Last 15 minutes', earliest: '-15m', latest: 'now' },
 *     { label: 'All time', earliest: '0', latest: '' },
 * ]
 * ```
 * @param {string} earliest - The time string such as `rt` , `rtnow` or `-2h@m`.
 * @param {string} latest - The time string such as `rt` , `rtnow` or `-2h@m`.
 * @returns {string | false } The matched preset label.
 */
export function findPresetLabel(presets, earliest, latest) {
    /* JSDoc and eslint cannot agree on the appropriate format of this */
    /* eslint-disable */
    const currentPreset = find(presets, preset => {
        const range2 = { earliest: preset.earliest, latest: preset.latest };

        return timeRangesAreEquivalent({ earliest, latest }, range2);
    });
    /* eslint-enable */

    return currentPreset ? currentPreset.label : false;
}

/**
 * Generates a label for a real-time time search.
 * For example:  '5 minute window' or 'Real-time'
 * @private
 * @param {string} earliest - The time string such as `rt` , `rtnow` or `-2h@m`.
 * @param {string} latest - The time string such as `rt` , `rtnow` or `-2h@m`.
 * @returns {null|string}
 */
function createRealTimeLabel(earliest, latest) {
    if (isRealTime(earliest) || isRealTime(latest)) {
        const earliestParse = parseTimeString(earliest);
        const earliestModifier = earliestParse.modifiers[0];
        const latestParse = parseTimeString(latest);

        const labelTemplates = {
            s: _('%(time)d second window'),
            m: _('%(time)d minute window'),
            h: _('%(time)d hour window'),
            d: _('%(time)d day window'),
            w: _('%(time)d week window'),
            mon: _('%(time)d month window'),
            q: _('%(time)d quarter window'),
            y: _('%(time)d year window'),
        };

        if (
            earliestModifier &&
            earliestParse.type === 'realTime' &&
            latestParse.type === 'realTime' &&
            latestParse.modifiers.length === 0 &&
            has(labelTemplates, earliestModifier.unit) &&
            earliestParse.modifiers.length === 1
        ) {
            return sprintf(labelTemplates[earliestModifier.unit], {
                time: Math.abs(earliestModifier.amount),
            });
        }

        // Other Real-Time.
        return _('Real-time');
    }
    return false;
}

/**
 * Generates a label for a relative time search.
 * For example:  'Last 5 minutes'
 * @private
 * @param {string} earliest - The time string such as `rt` , `rtnow` or `-2h@m`.
 * @param {string} latest - The time string such as `rt` , `rtnow` or `-2h@m`.
 * @returns {null|string}
 */
function createRelativeTimeLabel(earliest, latest) {
    const earliestParse = parseTimeString(earliest);
    const earliestModifier = earliestParse.modifiers[0];
    const latestParse = parseTimeString(latest);
    const latestModifier = latestParse.modifiers[0];

    if (
        earliestParse.type === 'realTime' ||
        latestParse.type === 'realTime' ||
        !earliestModifier ||
        earliestParse.modifiers.length > 1 ||
        latestParse.modifiers.length > 1
    ) {
        return false;
    }

    if (
        earliestModifier.amount < 0 &&
        (!earliestModifier.snap || earliestModifier.unit === earliestModifier.snap) &&
        (isLatestNow(latest) || (latestModifier && !latestModifier.amount)) &&
        (!latestModifier || !latestModifier.snap || earliestModifier.unit === latestModifier.snap)
    ) {
        const amount = Math.abs(earliestModifier.amount);
        const pluralKey = amount > 1 ? 'plural' : 'singular';
        return sprintf(_('Last %(amount)d %(unit)s'), {
            amount,
            unit: timeUnits[earliestModifier.unit][pluralKey],
        });
    }
    return false;
}

/**
 * Compares two moment instances to determine if they are on the same day.
 * @private
 * @param {object} earliest - @splunk/moment time instance
 * @param {object} latest - @splunk/moment time instance
 * @returns {bool}
 */
function areSameDay(a, b) {
    const aTime = a.clone().startOf('day');
    const bTime = b.clone().startOf('day');
    return aTime.isSame(bTime);
}

/**
 * Determines whether the date is the start of a year, month or day.
 * @private
 * @param {object} date - @splunk/moment time instance
 * @returns {'year'|'month'|'date'|undefined}
 */
function largestDateUnit(date) {
    return find(['year', 'month', 'date'], unit => {
        const dateStart = date.clone().startOf(unit);
        return date.isSame(dateStart);
    });
}
/**
 * Finds the largest date unit that can describe a date range: years, months or days.
 * @private
 * @param {object} earliest - @splunk/moment time instance
 * @param {object} latest - @splunk/moment time instance
 * @returns {'year'|'month'|'date'|null}
 */
function findSingleDateUnit(a, b) {
    if (!(isWholeDay(a) && isWholeDay(b))) {
        return null;
    }

    if (a.month() === 0 && b.month() === 0 && a.date() === 1 && b.date() === 1) {
        return 'year';
    }

    if (
        (a.year() === b.year() && a.date() === 1 && b.date() === 1) ||
        (a.year() + 1 === b.year() && b.month() === 0 && a.date() === 1 && b.date() === 1)
    ) {
        return 'month';
    }

    if (
        (a.year() === b.year() && a.month() === b.month()) ||
        (a.year() === b.year() && a.month() + 1 === b.month() && b.date() === 1) ||
        (a.year() + 1 === b.year() && b.month() === 0)
    ) {
        return 'date';
    }

    return null;
}
/**
 * Generates a date range label for a single year, month or day.
 * For example:  '2017', 'Feb 2017' or 'Feb 18, 2017'
 * @private
 * @param {object} date - @splunk/moment time instance
 * @param {'year'|'month'|'date'} unit
 * @returns {string}
 */
function createSingleUnitOnLabel(date, unit) {
    switch (unit) {
        case 'year':
            return date.format('YYYY');
        case 'month':
            return sprintf(_('%(month)s %(year)s'), {
                month: date.format('MMM'),
                year: date.format('YYYY'),
            });
        default:
            return date.format('ll');
    }
}
/**
 * Generates a date range label for a range of years, months or days.
 * For example:  '2015 though 2017', 'Feb through Apr, 2017' or 'Feb 17 through 18, 2017'
 * @private
 * @param {object} earliest - @splunk/moment time instance
 * @param {object} latest - @splunk/moment time instance
 * @param {'year'|'month'|'date'} unit
 * @returns {string}
 */
function createSingleUnitThroughLabel(a, b, unit) {
    const b2 = b.subtract(1, unit === 'date' ? 'day' : unit);

    switch (unit) {
        case 'year':
            return sprintf(_('%(earliestYear)s through %(latestYear)s'), {
                earliestYear: a.format('YYYY'),
                latestYear: b2.format('YYYY'),
            });
        case 'month':
            return sprintf(_('%(earliestMonth)s through %(latestMonth)s, %(inYear)s'), {
                earliestMonth: a.format('MMM'),
                latestMonth: b2.format('MMM'),
                inYear: b2.format('YYYY'),
            });
        default:
            return sprintf(_('%(month)s %(earliestDayOfMonth)s through %(latestDayOfMonth)s, %(inYear)s'), {
                month: a.format('MMM'),
                earliestDayOfMonth: a.format('D'),
                latestDayOfMonth: b2.format('D'),
                inYear: b2.format('YYYY'),
            });
    }
}
/**
 * Generates date-time label with the minimum specificity for time:minute, seconds or milliseconds.
 * For example:  'Feb 18, 2017 4:12:30.567 AM'
 * @private
 * @param {object} date - @splunk/moment time instance
 * @returns {string}
 */
function createDateTimeLabel(date) {
    if (date.millisecond()) {
        return date.splunkFormat('llms');
    }
    if (date.second()) {
        return date.splunkFormat('lls');
    }
    return date.splunkFormat('lll');
}
/**
 * Generates time label with the minimum specificity for time: minute, seconds or milliseconds.
 * For example:  '6:00 AM', '6:00:20 AM' or '6:00:20.712 AM'
 * @private
 * @param {object} time - @splunk/moment time instance
 * @returns {string}
 */
function createTimeLabel(time) {
    if (time.millisecond()) {
        return time.splunkFormat('LTMS');
    }
    if (time.second()) {
        return time.format('LTS');
    }
    return time.format('LT');
}

/**
 * Generates a date-time label with the minimum specificity for time: minute, seconds or milliseconds.
 * For example: 'Feb 17, 2017 6:00 AM to Feb 18, 2017 12:20 AM'
 * @private
 * @param {object} earliestTime - @splunk/moment time instance
 * @param {object} latestTime - @splunk/moment time instance
 * @returns {string}
 */
function createTimeRangeLabel(a, b) {
    let format;

    if (a.millisecond() || b.millisecond()) {
        format = 'llms';
    } else if (a.second() || b.second()) {
        format = 'lls';
    } else {
        format = 'lll';
    }

    return sprintf(_('%(earliestDateTime)s to %(latestDateTime)s'), {
        earliestDateTime: a.splunkFormat(format),
        latestDateTime: b.splunkFormat(format),
    });
}

/**
 * Generates a label for a time range on a single date.
 * For example: '6:00:20.850 AM to 8:00:40.490 PM, Feb 17, 2017'
 * @private
 * @param {object} earliestTime - @splunk/moment time instance
 * @param {object} latestTime - @splunk/moment time instance
 * @returns {string}
 */
function createPartOfDayLabel(a, b) {
    return sprintf(_('%(earliestTime)s to %(latestTime)s, %(date)s'), {
        earliestTime: createTimeLabel(a),
        latestTime: createTimeLabel(b),
        date: a.format('ll'),
    });
}

function conformToMaxChars(label, shortLabel, maxChars) {
    // if label is shorter than the shortLabel and longer than maxChars, use the label.
    // This scenario may arise due to long translations of the shortLabel.
    return maxChars && label.length > Math.max(shortLabel.length, maxChars) ? shortLabel : label;
}
/**
 * Generates a label for a date range when provided two ISO date formats.
 * @private
 * @param {string} earliest - An ISO time string, or other splunk time string.
 * @param {string} latest - An ISO time string, or other splunk time string .
 * @param {number} maxChars - If the generated label is too long, it will abbreviate
   to a more generic form, such as 'Between Date-times' instead of 'Feb 17, 2017 6:00 AM to Feb 18, 2017 12:20 AM'.
 * @returns {null|string}
 */
function createDateTimeRangeLabel(earliest, latest, maxChars) {
    const a = isISO(earliest) ? moment(earliest).locale(locale || 'en_US') : undefined;
    const b = isISO(latest) ? moment(latest).locale(locale || 'en_US') : undefined;

    if (a && b) {
        // During Year, Month Day
        const unit = findSingleDateUnit(a, b);

        // Single Year, Month or Day
        const next = unit && moment(a).add(1, unit === 'date' ? 'days' : unit);
        if (unit && next[unit]() === b[unit]()) {
            return createSingleUnitOnLabel(a, unit);
        }
        if (unit) {
            return conformToMaxChars(createSingleUnitThroughLabel(a, b, unit), _('Date Range'), maxChars);
        }

        // Part of Day
        if (areSameDay(a, b)) {
            return conformToMaxChars(createPartOfDayLabel(a, b), _('Part of a Day'), maxChars);
        }

        // Full
        return conformToMaxChars(createTimeRangeLabel(a, b), _('Between Date-times'), maxChars);
    }
    if (a && isLatestNow(latest)) {
        // Since
        const unit = largestDateUnit(a);
        const since = unit ? createSingleUnitOnLabel(a, unit) : createDateTimeLabel(a);
        const longLabel = sprintf(_('Since %(dateTime)s'), { dateTime: since });
        return conformToMaxChars(longLabel, _('Since Date-time'), maxChars);
    }
    if (isEarliestEmpty(earliest) && b) {
        // Before
        const unit = largestDateUnit(b);
        const before = unit ? createSingleUnitOnLabel(b, unit) : createDateTimeLabel(b);
        const longLabel = sprintf(_('Before %(dateTime)s'), { dateTime: before });
        return conformToMaxChars(longLabel, _('Before Date-time'), maxChars);
    }

    return null;
}
/**
 * Returns 'All-time' is the search is all-time or a close equivalent.
 * @private
 * @param {string} earliest - The time string such as `rt` , `rtnow` or `-2h@m`.
 * @param {string} latest - The time string such as `rt` , `rtnow` or `-2h@m`.
 * @returns {string}
 */
function createAllTimeLabel(earliest, latest) {
    if (isEarliestEmpty(earliest) && isLatestNow(latest)) {
        return _('All time');
    }
    return false;
}

/**
 * Creates an appropriate label for a time range, using a preset label if available.
 * @public
 * @param {string} earliest - The time string such as `rt` , `rtnow` or `-2h@m`.
 * @param {string} latest - The time string such as `rt` , `rtnow` or `-2h@m`.
 * @param {object} [options] - An array of presents such as:
 * @param {string} [options.locale='en_US'] - An array of presents such as:
 * @param {number} [options.maxChars] - If the generated label is too long, it will abbreviate
 * to a more generic form, such as 'Between Date-times' instead of 'Feb 17, 2017 6:00 AM to Feb 18, 2017 12:20 AM'.
 * `Infinity` and `0` allow labels of any length.
 * @param {array} [options.presets] - An array of presents such as:
 * ```
 * [
 *     { label: '30 second window', earliest: 'rt-30s', latest: 'rt' },
 *     { label: 'Today', earliest: '@d', latest: 'now' },
 *     { label: 'Previous year', earliest: '-1y@y', latest: '@y' },
 *     { label: 'Last 15 minutes', earliest: '-15m', latest: 'now' },
 *     { label: 'All time', earliest: '0', latest: '' },
 * ]
 * ```
 * @returns {string} Returns `'Custom time'` if one cannot be made.
 */
export function createRangeLabel(earliest, latest, { presets, maxChars } = {}) {
    const earliestTrimed = earliest.trim();
    const latestTrimed = latest.trim();

    return (
        createAllTimeLabel(earliestTrimed, latestTrimed) ||
        (presets && findPresetLabel(presets, earliestTrimed, latestTrimed)) ||
        createRealTimeLabel(earliestTrimed, latestTrimed) ||
        createRelativeTimeLabel(earliestTrimed, latestTrimed) ||
        createDateTimeRangeLabel(earliestTrimed, latestTrimed, maxChars) ||
        _('Custom time')
    );
}

function pluralize(singular, plural, numberOf) {
    if (numberOf === 0) {
        return null;
    }
    return numberOf === 1 ? singular : sprintf(plural, { numberOf });
}

/**
 * Takes a duration in milliseconds and returns a string describing the duration in terms of
 * years, months, days, hours, minutes, seconds and milliseconds.
 * If a unit isn't needed it's omitted, e.g. durations less than a year won't include '0 years'.
 * @public
 * @param {string|number} ms - The duration in milliseconds.
 * @returns {string} A formatted duration string, for example `27 days 16 hours 36 minutes 59 seconds`.
 * Durations <= 0 return `null`.
 */
export function formatDuration(ms) {
    if (ms <= 0) {
        return null;
    }

    const duration = moment.duration(ms);
    return [
        pluralize(_('1 year'), _('%(numberOf)d years'), duration.years()),
        pluralize(_('1 month'), _('%(numberOf)d months'), duration.months()),
        pluralize(_('1 day'), _('%(numberOf)d days'), duration.days()),
        pluralize(_('1 hour'), _('%(numberOf)d hours'), duration.hours()),
        pluralize(_('1 minute'), _('%(numberOf)d minutes'), duration.minutes()),
        pluralize(_('1 second'), _('%(numberOf)d seconds'), duration.seconds()),
        pluralize(_('1 millisecond'), _('%(numberOf)d milliseconds'), duration.milliseconds()),
    ]
        .filter(display => !!display)
        .join(' ');
}

export const parse = (time, baseTime = epochToMoment()) => {
    const parsed = parseTimeString(time);
    if (time === '') {
        return { error: new Error('Invalid time string'), time };
    }
    if (!parsed.isFullyParsed) {
        return { error: new Error('The time string could not be parsed'), time };
    }
    if (parsed.type === 'iso') {
        return { error: null, iso: time, time };
    }
    if (parsed.type === 'epoch') {
        return { error: null, iso: epochToMoment(parseFloat(time)).format(), time };
    }
    const date = moment(baseTime);
    parsed.modifiers.forEach(mod => {
        if (mod.amount && momentUnits[mod.unit]) {
            date.add(mod.amount, momentUnits[mod.unit]);
        }
        if (mod.snap) {
            if (momentSnaps[mod.snap]) {
                date.startOf(momentSnaps[mod.snap]);
            } else if (weekDaySnaps[mod.snap] != null) {
                date.startOf(momentSnaps.w).add(weekDaySnaps[mod.snap], 'd');
                if (epochToMoment().isBefore(date)) {
                    // always snap to past time, so we need to shift back for 1 week.
                    date.add(-1, 'w');
                }
            }
        }
    });
    return { error: null, iso: date.format(), time, momentTime: date };
};

export const defaultTimePreset = [
    { label: _('Today'), earliest: '@d', latest: 'now' },
    { label: _('Week to date'), earliest: '@w0', latest: 'now' },
    { label: _('Business week to date'), earliest: '@w1', latest: 'now' },
    { label: _('Month to date'), earliest: '@mon', latest: 'now' },
    { label: _('Year to date'), earliest: '@y', latest: 'now' },
    { label: _('Yesterday'), earliest: '-1d@d', latest: '@d' },
    { label: _('Previous week'), earliest: '-7d@w0', latest: '@w0' },
    { label: _('Previous business week'), earliest: '-6d@w1', latest: '-1d@w6' },
    { label: _('Previous month'), earliest: '-1mon@mon', latest: '@mon' },
    { label: _('Previous year'), earliest: '-1y@y', latest: '@y' },
    { label: _('Last 15 minutes'), earliest: '-15m', latest: 'now' },
    { label: _('Last 60 minutes'), earliest: '-60m@m', latest: 'now' },
    { label: _('Last 4 hours'), earliest: '-4h@m', latest: 'now' },
    { label: _('Last 24 hours'), earliest: '-24h@h', latest: 'now' },
    { label: _('Last 7 days'), earliest: '-7d@h', latest: 'now' },
    { label: _('Last 30 days'), earliest: '-30d@d', latest: 'now' },
    { label: _('All time'), earliest: '0', latest: 'now' },
];

export const defaultRealTimePreset = [
    { label: _('30 second window'), earliest: 'rt-30s', latest: 'rt' },
    { label: _('1 minute window'), earliest: 'rt-1m', latest: 'rt' },
    { label: _('5 minute window'), earliest: 'rt-5m', latest: 'rt' },
    { label: _('30 minute window'), earliest: 'rt-30m', latest: 'rt' },
    { label: _('1 hour window'), earliest: 'rt-1h', latest: 'rt' },
    { label: _('All time (real-time)'), earliest: 'rt', latest: 'rt' },
].concat(defaultTimePreset);
