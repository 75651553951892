/* eslint-disable no-constant-condition */
import { eventChannel, buffers } from 'redux-saga';
import { put, take, cancelled } from 'redux-saga/effects';
import console, { logfmt } from '@splunk/dashboard-utils/console';
import { triggerEvent } from './sagaActions';

/**
 * dispatch dataSource event into saga flow
 * @param {Object} sagaContext saga context object
 * @public
 */
export default function* dataSourceSaga(sagaContext) {
    try {
        const channel = eventChannel(emit => {
            sagaContext.dataSourceRegistry.onEventBroadcast(event => {
                emit(event);
            });
            return () => {};
        }, buffers.expanding(10));
        // prettier-ignore
        while (true) { // NOSONAR
            const { eventType, targetId, payload } = yield take(channel);
            yield put(triggerEvent(targetId, eventType, payload));
        }
    } catch (error) {
        if (!(yield cancelled())) {
            console.error(...logfmt`Caught error: ${error}`);
        }
    } finally {
        // do nothing
    }
}
