import cloneDeep from 'lodash/cloneDeep';
import each from 'lodash/each';
import omit from 'lodash/omit';
import get from 'lodash/get';
import isString from 'lodash/isString';
import isEmpty from 'lodash/isEmpty';
import Ajv from 'ajv';
import ShortUniqueId from 'short-unique-id';
import schema from './DashboardSchema';

const uid = new ShortUniqueId();

const nextVizId = () => `viz_${uid.randomUUID(8)}`;
const nextDSId = () => `ds_${uid.randomUUID(8)}`;
const ajv = new Ajv({ allErrors: true });

export const DEFAULT_DEFINITION = {
    visualizations: {},
    dataSources: {},
    inputs: {},
    layout: {
        type: 'absolute',
        options: {},
        structure: [],
    },
};

/**
 * A dashboard definition helper class
 * @class DashboardDefinition
 */
class DashboardDefinition {
    /**
     * Creates a new DashboardDefinition based on input def
     * @method fromJSON
     * @param {Object} [def] A dashboard definition
     * @returns {DashboardDefinition}
     * @static
     */
    static fromJSON(def = {}) {
        return new DashboardDefinition(def);
    }

    /**
     * Creates a new DashboardDefinition based on input def
     * @param {Object} [def] A dashboard definition
     * @returns {DashboardDefinition}
     * @constructor
     */
    constructor(def = {}) {
        this.definition = def;
        this.setSchema(schema);
    }

    /**
     * set up customized schema
     * @method setSchema
     * @param {Object} newSchema
     * @returns {Object} error
     */
    setSchema(newSchema) {
        try {
            this.validateDefinition = ajv.compile(newSchema);
        } catch (error) {
            return error;
        }
        return null;
    }

    /**
     * check duplication of inputs tokens
     * @method checkDuplicateTokens
     * @returns {Array} errors
     */
    checkDuplicateTokens() {
        const cache = {};
        const { inputs } = this.definition;
        const errors = [];
        each(inputs, (input, key) => {
            const { token } = input.options;
            if (token in cache) {
                cache[token].push(key);
            } else {
                cache[token] = [key];
            }
        });
        each(cache, (keys, token) => {
            if (keys.length > 1) {
                const lastKey = keys[keys.length - 1];
                const msg = keys.slice(0, -1).join(', ');
                errors.push({
                    dataPath: 'inputs duplicated token error',
                    message: `${msg} and ${lastKey} have the same token (${token})`,
                });
            }
        });

        return errors;
    }

    /**
     * Validates the current definition
     * @method validate
     * @returns {Array} list of errors, or null
     */
    validate() {
        const valid = this.validateDefinition(this.definition);
        if (!valid && this.validateDefinition.errors.length > 0) {
            return cloneDeep(this.validateDefinition.errors);
        }
        const res = this.checkDuplicateTokens();
        if (!isEmpty(res)) {
            return res;
        }
        return null;
    }

    /**
     * Update the dashboard title or description
     * @method updateDashboard
     * @param {String} title The new title
     * @param {String} desc The new description
     * @returns {DashboardDefinition}
     */
    updateDashboard({ title, desc }) {
        if (isString(title)) {
            this.definition = {
                ...this.definition,
                title: (title && title.trim()) || '',
            };
        }
        if (desc) {
            this.definition = {
                ...this.definition,
                description: desc,
            };
        }
        return this;
    }

    /**
     * Add a new datasource configuration
     * @method addDataSource
     * @param {String} dsId     The key to identify the datasource
     * @param {Object} [dsDef]    The configuration
     * @returns {DashboardDefinition}
     */
    addDataSource(dsId, dsDef = {}) {
        this.definition = {
            ...this.definition,
            dataSources: {
                ...this.definition.dataSources,
                [dsId]: dsDef,
            },
        };
        return this;
    }

    /**
     * Add a visualization configuration to the definition
     * @method addVisualization
     * @param {String} vizId    The key to identify the vis
     * @param {Object} [vizDef]   The configuration of the vis
     * @returns {DashboardDefinition}
     */
    addVisualization(vizId, vizDef = {}) {
        // TODO: should this also add to layout structure?
        this.definition = {
            ...this.definition,
            visualizations: {
                ...this.definition.visualizations,
                [vizId]: vizDef,
            },
        };
        return this;
    }

    /**
     * Add an input config to the definition
     * @method addInput
     * @param {String} inputId      Key to identify the input
     * @param {Object} [inputDef]     The input config
     * @returns {DashboardDefinition}
     */
    addInput(inputId, inputDef = {}) {
        this.definition = {
            ...this.definition,
            inputs: {
                ...this.definition.inputs,
                [inputId]: inputDef,
            },
        };
        return this;
    }

    /**
     * Clones a datasource configuration
     * @method cloneDataSource
     * @param {String} dsId     Key to identify datasource
     * @returns {String} The newly created DatasourceId
     */
    cloneDataSource(dsId) {
        let dsDefinition = this.getDataSource(dsId);
        if (dsDefinition) {
            if (dsDefinition.name) {
                dsDefinition = {
                    ...dsDefinition,
                    name: `Copy of ${dsDefinition.name}`,
                };
            }
            const newDatasourceId = `${this.nextDataSourceId()}_${dsId}`;
            this.addDataSource(newDatasourceId, dsDefinition);
            return newDatasourceId;
        }
        return null;
    }

    /**
     * Clones a Visualization configuration
     * @method cloneVisualization
     * @param {String} vizId     Key to identify Viz
     * @param {String} newVizId  Key for new cloned Viz
     * @returns {String} The newly created VizId
     */
    cloneVisualization(vizId, newVizId) {
        let vizDef = this.getVisualization(vizId);
        if (vizDef) {
            each(vizDef.dataSources, (dataSourceId, dataSourceType) => {
                if (this.getDataSource(dataSourceId)) {
                    const newDatasourceId = this.cloneDataSource(dataSourceId);
                    vizDef = {
                        ...vizDef,
                        dataSources: {
                            ...vizDef.dataSources,
                            [dataSourceType]: newDatasourceId,
                        },
                    };
                }
            });
            this.addVisualization(newVizId, vizDef);
            return newVizId;
        }
        return null;
    }

    /**
     * Removes a datasource configuration
     * @method removeDataSource
     * @param {String} dsId     Key to identify datasource
     * @returns {DashboardDefinition}
     */
    removeDataSource(dsId) {
        this.definition = {
            ...this.definition,
            dataSources: omit(this.definition.dataSources, [dsId]),
        };
        return this;
    }

    /**
     * Removes a visualization configuration
     * @method removeVisualization
     * @param {String} vizId key to identify visualization
     * @returns {DashboardDefinition}
     */
    removeVisualization(vizId) {
        // TODO: also remove from structure?
        this.definition = {
            ...this.definition,
            visualizations: omit(this.definition.visualizations, [vizId]),
        };
        return this;
    }

    /**
     * Removes an input configuration
     * @method removeInput
     * @param {String} inputId key to identify input
     * @returns {DashboardDefinition}
     */
    removeInput(inputId) {
        this.definition = {
            ...this.definition,
            inputs: omit(this.definition.inputs, [inputId]),
        };
        return this;
    }

    /**
     * Update the layout to a different type
     * @method updateLayoutType
     * @param {String} type     Layout type, e.g. grid, absolute, rowcolumn
     * @returns {DashboardDefinition}
     */
    updateLayoutType(type) {
        this.definition = {
            ...this.definition,
            layout: {
                ...this.definition.layout,
                type,
            },
        };
        return this;
    }

    /**
     * Replaces current layout options config
     * @method updateLayoutOptions
     * @param {Object} layoutOptions Options object to replace existing def
     * @returns {DashboardDefinition}
     */
    updateLayoutOptions(layoutOptions) {
        this.definition = {
            ...this.definition,
            layout: {
                ...this.definition.layout,
                options: layoutOptions,
            },
        };
        return this;
    }

    /**
     * Replace current structure with a new one
     * @method updateLayoutStructure
     * @param {Array} newStructure List of vis layout item position data
     * @returns {DashboardDefinition}
     */
    updateLayoutStructure(newStructure) {
        this.definition = {
            ...this.definition,
            layout: {
                ...this.definition.layout,
                structure: newStructure,
            },
        };
        return this;
    }

    /**
     * Replace a visualization config
     * @method updateVisualization
     * @param {String} vizId  key to identify vis
     * @param {Object} [vizDef] Visualization definition
     * @returns {DashboardDefinition}
     */
    updateVisualization(vizId, vizDef = {}) {
        this.definition = {
            ...this.definition,
            visualizations: {
                ...this.definition.visualizations,
                [vizId]: vizDef,
            },
        };
        return this;
    }

    /**
     * Replaces existing datasource config
     * @method updateDataSource
     * @param {String} dsId     key to identify datasource
     * @param {Object} [dsDef]    The datasource definition
     * @returns {DashboardDefinition}
     */
    updateDataSource(dsId, dsDef = {}) {
        this.definition = {
            ...this.definition,
            dataSources: {
                ...this.definition.dataSources,
                [dsId]: dsDef,
            },
        };
        return this;
    }

    /**
     * Replaces existing input config
     * @method updateInput
     * @param {String} inputId key to identify input
     * @param {Object} [inputDef] New input config
     * @returns {DashboardDefinition}
     */
    updateInput(inputId, inputDef = {}) {
        this.definition = {
            ...this.definition,
            inputs: {
                ...this.definition.inputs,
                [inputId]: inputDef,
            },
        };
        return this;
    }

    /**
     * Return the JSON representation of the dashboard
     * @method toJSON
     * @returns {Object}
     */
    toJSON() {
        return this.definition;
    }

    /**
     * Get the current layout structure
     * @method getLayoutStructure
     * @returns {Array} The current structure
     */
    getLayoutStructure() {
        const structure = get(this.definition, 'layout.structure');

        return Array.isArray(structure) ? structure : [];
    }

    /**
     * return current layout type
     */
    getLayoutType() {
        return get(this.definition, 'layout.type');
    }

    /**
     * Return the current options for the layout
     * @method getLayoutOptions
     * @returns {Object}
     */
    getLayoutOptions() {
        return get(this.definition, 'layout.options') || {};
    }

    /**
     * Fetch the current definition for a visualization by id
     * @method getVisualization
     * @param {String} visId    The identifier for the vis
     * @returns {Object} The vis definition, or null if not found
     */
    getVisualization(visId) {
        return get(this.definition, `visualizations["${visId}"]`) || null;
    }

    /**
     * Fetch all the visualization ids
     * @method getVisualizationIds
     * @returns {Array} All the Viz Ids
     */
    getVisualizationIds() {
        return Object.keys(get(this.definition, 'visualizations') || {});
    }

    /**
     * Fetch the current definition for a datasource by id
     * @param {*} dsId
     * @returns {Object} The datasource definition, or null if not found
     */
    getDataSource(dsId) {
        return get(this.definition, `dataSources["${dsId}"]`) || null;
    }

    /**
     * Get the type for the given visualization
     * @method getVisualizationType
     * @param {String} visId key to identify visualization
     * @returns {String}
     */
    getVisualizationType(visId) {
        return get(this.definition, `visualizations["${visId}"].type`) || null;
    }

    /**
     * return event handler array
     * @param {*} hostId node id, can be searchId, vizId or inputId
     * @param {*} type can be visualizations, dataSources or inputs
     */
    getEventHandlers(hostId, type = 'visualizations') {
        const host = get(this.definition, [type, hostId], null);
        return (host && Array.isArray(host.eventHandlers) && host.eventHandlers) || [];
    }

    /**
     *
     * @param {*} hostId node id, can be search id, viz id or input id
     * @param {*} handler eventhandler
     * @param {*} type can be visualizations, dataSources or inputs
     */
    createEventHandler(hostId, handler = {}, type = 'visualizations') {
        const host = get(this.definition, [type, hostId], null);
        if (host == null) {
            return this;
        }
        const eventHandlers = [...this.getEventHandlers(hostId, type), handler];
        this.definition = {
            ...this.definition,
            [type]: {
                ...this.definition[type],
                [hostId]: {
                    ...this.definition[type][hostId],
                    eventHandlers,
                },
            },
        };
        return this;
    }

    /**
     *
     * @param {*} hostId node id, can be search id, viz id or input id
     * @param {*} handlerIdx handler index
     * @param {*} type can be visualizations, dataSources or inputs
     */
    removeEventHandler(hostId, handlerIdx = 0, type = 'visualizations') {
        const host = get(this.definition, [type, hostId], null);
        if (host == null) {
            return this;
        }
        const eventHandlers = [...this.getEventHandlers(hostId, type)];
        // delete 1 element at handlerIdx
        eventHandlers.splice(handlerIdx, 1);
        this.definition = {
            ...this.definition,
            [type]: {
                ...this.definition[type],
                [hostId]: {
                    ...this.definition[type][hostId],
                    eventHandlers,
                },
            },
        };
        return this;
    }

    /**
     *
     * @param {*} hostId node id, can be search id, viz id or input id
     * @param {*} handlerIdx handler index
     * @param {*} handler new handler
     * @param {*} type can be visualizations, dataSources or inputs
     */
    editEventHandler(hostId, handlerIdx = 0, handler = {}, type = 'visualizations') {
        const host = get(this.definition, [type, hostId], null);
        if (host == null) {
            return this;
        }
        const eventHandlers = [...this.getEventHandlers(hostId, type)];
        if (handlerIdx >= 0 && handlerIdx <= eventHandlers.length - 1) {
            eventHandlers[handlerIdx] = handler;
            this.definition = {
                ...this.definition,
                [type]: {
                    ...this.definition[type],
                    [hostId]: {
                        ...this.definition[type][hostId],
                        eventHandlers,
                    },
                },
            };
        }
        return this;
    }

    /**
     * connect a visualization with new datasource
     * @param {*} vizId visualization id
     * @param {*} dataSourceType dataSource binding type such as primary, annotation
     * @param {*} dataSourceDefinition dataSoure definition
     */
    connectNewDataSourceToVisualization(vizId, dataSourceType, dataSourceDefinition) {
        const dsId = this.nextDataSourceId();
        const visualization = this.getVisualization(vizId);
        this.addDataSource(dsId, dataSourceDefinition);
        this.updateVisualization(vizId, {
            ...visualization,
            dataSources: {
                ...visualization.dataSources,
                [dataSourceType]: dsId,
            },
        });
        return this;
    }

    /**
     * connect a visualization with existing datasource
     * @param {*} vizId visualization id
     * @param {*} dataSourceType dataSource binding type such as primary, annotation
     * @param {*} dataSourceId existing datasource id
     */
    connectDataSourceToVisualization(vizId, dataSourceType, dataSourceId) {
        const visualization = this.getVisualization(vizId);
        this.updateVisualization(vizId, {
            ...visualization,
            dataSources: {
                ...visualization.dataSources,
                [dataSourceType]: dataSourceId,
            },
        });
        return this;
    }

    /**
     * disconnect a visualization from existing datasource
     * @param {*} vizId visualization id
     * @param {*} dataSourceType dataSource binding type such as primary, annotation
     * @param {*} dataSourceId existing datasource id
     */
    disconnectDataSourceFromVisualization(vizId, dataSourceType, dataSourceId) {
        const visualization = this.getVisualization(vizId);
        if (visualization.dataSources) {
            if (visualization.dataSources[dataSourceType] === dataSourceId) {
                this.updateVisualization(vizId, {
                    ...visualization,
                    dataSources: omit(visualization.dataSources, dataSourceType),
                });
            }
        }
        return this;
    }

    /**
     * generate visualization id
     */
    nextVisualizationId() {
        let nextId = nextVizId();
        while (this.getVisualization(nextId) != null) {
            nextId = nextVizId();
        }
        return nextId;
    }

    /**
     * generate datasource id
     */
    nextDataSourceId() {
        let nextId = nextDSId();
        while (this.getDataSource(nextId) != null) {
            nextId = nextDSId();
        }
        return nextId;
    }

    /**
     * Returns the number of Visualizations using the Data Source with passed in dsId
     * @method countVisualizationsInUse
     * @param {String} dsId     Key to identify datasource
     * @returns {Number}
     */
    countVisualizationsUsingDataSource(dsId) {
        const { visualizations = {} } = this.definition;
        let visualizationUseCount = 0;
        Object.keys(visualizations).forEach(vizId => {
            const dataSources = get(visualizations, [vizId, 'dataSources'], {});
            Object.keys(dataSources).forEach(dataSourceType => {
                if (dataSources[dataSourceType] === dsId) {
                    visualizationUseCount += 1;
                }
            });
        });
        return visualizationUseCount;
    }

    /**
     * Disconnects a dataSource from all visualizations from the definition
     * @method disconnectDataSource
     * @param {String} dsId     Key to identify datasource
     * @returns {DashboardDefinition}
     */
    disconnectDataSource(dsId) {
        const { visualizations = {} } = this.definition;
        Object.keys(visualizations).forEach(vizId => {
            const dataSources = get(visualizations, [vizId, 'dataSources'], {});
            Object.keys(dataSources).forEach(dataSourceType => {
                if (dataSources[dataSourceType] === dsId) {
                    this.updateVisualization(vizId, {
                        ...visualizations[vizId],
                        dataSources: omit(dataSources, dataSourceType),
                    });
                }
            });
        });
        return this;
    }
}

export default DashboardDefinition;
