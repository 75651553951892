import get from 'lodash/get';
import { createAction, handleActions } from 'redux-actions';

/**
 * Actions
 */
export const setToken = createAction('SET_TOKEN', (tokens, namespace = 'default', submit = true) => ({
    tokens,
    namespace,
    submit,
}));
export const unsetToken = createAction('UNSET_TOKEN', (tokenName, namespace = 'default', submit = true) => ({
    tokenName,
    namespace,
    submit,
}));
export const updateTokens = createAction('UPDATE_TOKENS', (tokens, submit = true) => ({
    tokens,
    submit,
}));

export const submitTokens = createAction('SUBMIT_TOKENS', (namespace = 'default') => ({
    namespace,
}));

/**
 * Selectors
 */
export const selectSubmittedTokens = state => get(state, ['tokens', 'submitted'], {});
export const selectStashedTokens = state => get(state, ['tokens', 'stashed'], {});

/**
 * Reducers
 */
export default handleActions(
    {
        [updateTokens]: (state, { payload = {} }) => {
            const { tokens, submit } = payload;
            const space = submit ? 'submitted' : 'stashed';
            return {
                ...state,
                [space]: tokens,
            };
        },
        [setToken]: (state, { payload }) => {
            const { tokens, namespace, submit } = payload;
            const space = submit ? 'submitted' : 'stashed';
            return {
                ...state,
                [space]: {
                    ...state[space],
                    [namespace]: {
                        ...get(state, [space, namespace]),
                        ...tokens,
                    },
                },
            };
        },
        [unsetToken]: (state, { payload }) => {
            const { tokenName, namespace, submit } = payload;
            const space = submit ? 'submitted' : 'stashed';
            return {
                ...state,
                [space]: {
                    ...state[space],
                    [namespace]: {
                        ...get(state, [space, namespace]),
                        [tokenName]: null,
                    },
                },
            };
        },
        [submitTokens]: (state, { payload }) => {
            // move tokens from stashed to submitted
            const { namespace } = payload;
            const tobeSubmit = get(state, ['stashed', namespace], {});
            return {
                stashed: {
                    ...state.stashed,
                    [namespace]: {},
                },
                submitted: {
                    ...state.submitted,
                    [namespace]: {
                        ...get(state, ['submitted', namespace]),
                        ...tobeSubmit,
                    },
                },
            };
        },
    },
    {
        stashed: {},
        submitted: {},
    }
);
