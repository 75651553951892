/* eslint-disable no-param-reassign */
import get from 'lodash/get';
import isFunction from 'lodash/isFunction';
import DataSet from '@splunk/datasource-utils/DataSet';

/**
 * Compute initial request params for given visualization and dataSource
 * @param {String} bindingType dataSource binding type
 * @param {Object} consumerModule module from preset
 * @param {Object} options current visualization/input options
 * @private
 */
export const findInitialRequestParams = ({ bindingType = 'primary', consumerModule, options = {} } = {}) => {
    if (consumerModule == null) {
        return undefined;
    }
    let initialRequestParams = get(consumerModule, 'initialRequestParams', null);
    if (consumerModule) {
        if (consumerModule.dataContract && consumerModule.dataContract.initialRequestParams) {
            initialRequestParams = consumerModule.dataContract.initialRequestParams[bindingType];
        }
    }
    if (isFunction(initialRequestParams)) {
        return initialRequestParams(options);
    }
    return initialRequestParams;
};

export const formatData = data => {
    if (data == null) {
        return DataSet.empty().toJSONCols();
    }
    if (data.constructor === DataSet) {
        return data.toJSONCols(); // we use json cols by default
    }
    if (data.fields && data.columns) {
        return data;
    }
    return null; // return null as invalid data
};
