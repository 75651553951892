import React, { PureComponent } from 'react';
import T from 'prop-types';
import { _ } from '@splunk/ui-utils/i18n';
import { connect } from 'react-redux';
import Message from '@splunk/dashboard-ui/Message';
import {
    selectLayout,
    selectVisualizations,
    selectDataSources,
    updateLayoutStructure,
} from '../../state/redux/definition';
import { selectSubmittedTokens } from '../../state/redux/tokens';
import { updateSelectedVisualizations, selectSelectedVisualizations } from '../../state/redux/editor';
import { selectMode } from '../../state/redux/mode';
import { triggerEvent } from '../../state/sagas/sagaActions';
import VisualizationContainer from '../VisualizationContainer';
import LineContainer from '../LineContainer';
import { replaceTokenForLayout } from '../../utils/token';

const mapStateToProps = state => ({
    layout: replaceTokenForLayout(selectLayout(state), selectSubmittedTokens(state)),
    visualizations: selectVisualizations(state),
    dataSources: selectDataSources(state),
    mode: selectMode(state),
    submittedTokens: selectSubmittedTokens(state),
    selectedLayoutItems: selectSelectedVisualizations(state),
});

const mapDispatchToProps = dispatch => ({
    handleEvent: (eventType, payload, eventId) => {
        dispatch(triggerEvent(null, eventType, payload, eventId));
    },
    onLayoutItemsSelect: selectedItems => {
        dispatch(updateSelectedVisualizations(selectedItems));
    },
    onLayoutStructureChange: layoutStructure => {
        dispatch(updateLayoutStructure(layoutStructure));
    },
});

class LayoutContainer extends PureComponent {
    constructor(props, context) {
        super(props, context);
        this.apiRegistry = context.apiRegistry;
    }

    componentDidMount() {
        if (this.layoutApi) {
            this.apiRegistry.registerLayoutApi(this.layoutApi);
        }
    }

    componentWillUnmount() {
        this.apiRegistry.removeLayoutApi();
    }

    render() {
        const {
            layout,
            visualizations,
            dataSources,
            submittedTokens,
            width, // container width in pixel
            height, // container height in pixel
            mode,
            showGrid,
            handleEvent,
            actionMenus,
            selectedLayoutItems,
            onLayoutItemsSelect,
            onLayoutStructureChange,
        } = this.props;
        const { preset, eventRegistry } = this.context;
        const { type, options, structure: layoutStructure } = layout;
        const layoutProps = {
            options,
            mode,
            // we send down the actual width/height of dashboard-core container
            containerWidth: width,
            containerHeight: height,
            showGrid,
            layoutStructure,
            renderActionMenus: id =>
                actionMenus.map(item =>
                    React.cloneElement(item, {
                        itemId: id,
                        visualization: visualizations[id],
                        dataSources,
                        submittedTokens,
                    })
                ),
            renderLayoutItem: (id, props, layoutItemType, onSelected) => {
                if (layoutItemType === 'line') {
                    return <LineContainer key={id} id={id} {...props} />;
                }
                return <VisualizationContainer key={id} id={id} {...props} onSelected={onSelected} />;
            },
            onEventTrigger: ({ type: eventType, originalEvent, payload }) => {
                const eventId = eventRegistry.registerEvent(originalEvent);
                handleEvent(eventType, payload, eventId);
            },
            selectedLayoutItems,
            onLayoutItemsSelect,
            onLayoutStructureChange,
            layoutApiRef: layoutApi => (this.layoutApi = layoutApi),
        };
        if (!preset.findLayout(type)) {
            return <Message level="error" message={_(`Layout ${type} is not defined`)} />;
        }
        return preset.createLayout(type, layoutProps);
    }
}

LayoutContainer.propTypes = {
    mode: T.string,
    /**
     * layout node from dashboard definition
     */
    layout: T.object,
    /**
     * visualization definitions
     */
    visualizations: T.object,
    /**
     * datasource definitions
     */
    dataSources: T.object,
    /**
     * current submitted tokens
     */
    submittedTokens: T.object,
    /**
     * container width in pixel
     */
    width: T.number,
    /**
     * container height in pixel
     */
    height: T.number,
    /**
     * action menus
     */
    actionMenus: T.arrayOf(T.object),
    /**
     * current selected items
     */
    selectedLayoutItems: T.array,
    /**
     * callback when item been selected
     */
    onLayoutItemsSelect: T.func,
    /**
     * callback when layout structure changed
     */
    onLayoutStructureChange: T.func,
    /**
     * handle event callback
     */
    handleEvent: T.func,
    /**
     * whether gridlines should be displayed in edit mode
     */
    showGrid: T.bool,
};

LayoutContainer.contextTypes = {
    apiRegistry: T.object,
    preset: T.object,
    eventRegistry: T.object,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LayoutContainer);
