/* global __UDF_VERSION__ */
import { pick } from 'lodash';
import React, { useMemo } from 'react';
import T from 'prop-types';
import styled from 'styled-components';
import { createThemeRegistry } from '@splunk/dashboard-themes/helper';
import SizeAwareWrapper from '@splunk/dashboard-ui/SizeAwareWrapper';
import { toDimension } from '@splunk/dashboard-utils/style';
import { createTestHookElementFactory } from '../utils/testSupport';

const themeRegistry = createThemeRegistry('@splunk/dashboard-core', 'DashboardLayout');

const testHook = createTestHookElementFactory(__filename);

const DashboardLayoutContainer = testHook(styled.div, 'DashboardLayoutContainer')`
    ${themeRegistry.mixin('reset')('flex')};
    position: relative;
    overflow: hidden;
    flex-direction: column;
    ${props => toDimension(pick(props, ['width', 'height']))};
`;

const GlobalInputsContainer = testHook(styled.div, 'GlobalInputsContainer')`
    flex-basis: auto;
    z-index: 100;
    margin-bottom: 8px;
`;

const LayoutContainer = testHook(styled.div, 'LayoutContainer')`
    flex-grow: 1;
    min-height: 0px;
    overflow: auto;
    position: relative;
`;

const DashboardLayout = ({ height, width, renderGlobalInput, renderLayout, showGlobalInputs }) => {
    const renderGlobalInputsContainer = useMemo(
        () => showGlobalInputs && <GlobalInputsContainer>{renderGlobalInput()}</GlobalInputsContainer>,
        [showGlobalInputs, renderGlobalInput]
    );
    return (
        <DashboardLayoutContainer
            height={height}
            width={width}
            data-udf-core-version={__UDF_VERSION__ || 'unknown'}
        >
            {renderGlobalInputsContainer}
            <LayoutContainer>
                <SizeAwareWrapper>
                    {containerDimensions => renderLayout(containerDimensions)}
                </SizeAwareWrapper>
            </LayoutContainer>
        </DashboardLayoutContainer>
    );
};

DashboardLayout.propTypes = {
    height: T.oneOfType([T.string, T.number]),
    width: T.oneOfType([T.string, T.number]),
    renderGlobalInput: T.func,
    renderLayout: T.func,
    showGlobalInputs: T.bool,
};

DashboardLayout.defaultProps = {
    renderGlobalInput: () => null,
    renderLayout: () => null,
    height: '100%',
    width: '100%',
    showGlobalInputs: false,
};

export const themes = themeRegistry.toThemes();

export default DashboardLayout;
