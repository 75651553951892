import each from 'lodash/each';
import bind from 'lodash/bind';
import hash from 'object-hash';

import DataSourceController from './DataSourceController';

/**
 * compute unique hash key for a given object
 * @param {Object} dataSourceDef
 */
const computeKey = dataSourceDef => {
    // same DataSource will be reused
    // todo, benchmark on how fast this hash function is
    return hash(dataSourceDef);
};

/**
 * A module that create reuseable DataSource controller
 */
class DataSourceRegistry {
    /**
     *
     * @param {Object} preset dashboard preset
     * @param {Object} dataSourceContext DataSource context
     * @returns {DataSourceRegistry}
     */
    constructor({ preset, dataSourceContext }) {
        this.preset = preset;
        this.dataSourceContext = dataSourceContext;
        this.controllers = {};
    }

    /**
     * Return a DataSource controller that matches the definition
     * @param {Object} dataSourceDef DataSource definition
     * @param {Object} dataSourceId DataSource id
     * @returns {DataSourceController} DataSource controller instance
     */
    allocate = ({ dataSourceDef, dataSourceId }) => {
        // allocate a DataSource controller that matches the definition.
        // an existing controller will be returned if definition matches,
        // otherwise a new one will be created and return.
        const key = computeKey(dataSourceDef);
        if (this.controllers[key] == null) {
            this.controllers[key] = new DataSourceController({
                id: dataSourceId,
                dataSourceDef,
                dataSourceContext: this.dataSourceContext,
                preset: this.preset,
            });
            this.controllers[key].onTeardown(bind(this.handleDataSourceTeardown, this, key));
            this.controllers[key].onDataSourceEvent(this.broadcastDataSourceEvent);
        }
        return this.controllers[key];
    };

    /**
     * Remove DataSource controller from registry
     * @param {String} key cached key
     */
    handleDataSourceTeardown = key => {
        delete this.controllers[key];
    };

    /**
     * Broadcast DataSource event via broadcastCallback
     * @param {Object} event DataSource event
     */
    broadcastDataSourceEvent = event => {
        if (this.broadcastCallback) {
            this.broadcastCallback(event);
        }
    };

    /**
     * register event broadcast callback
     * @param {Function} callback broadcast callback
     */
    onEventBroadcast = callback => {
        this.broadcastCallback = callback;
    };

    /**
     * capture the snapshot of all DataSources
     */
    snapshot() {
        const state = {};
        each(this.controllers, controller => {
            const subs = controller.getSubscriptions();
            const subState = {};
            each(subs, sub => {
                subState[sub.consumerId] = sub.getLastState();
            });
            state[controller.id] = subState;
        });
        return state;
    }
}

export default DataSourceRegistry;
