import isFinite from 'lodash/isFinite';
import isString from 'lodash/isString';
import { parse, epochToMoment } from '@splunk/dashboard-utils/time';

const minInterval = 3; // 3 secs

const computeInterval = (interval, refreshType) => {
    return refreshType === 'delay' ? interval : Math.max(minInterval, interval);
};

export const parseExprToSeconds = (exp = '5s') => {
    try {
        if (isFinite(exp)) {
            return Math.abs(parseInt(exp, 10));
        }
        if (isString(exp)) {
            const now = epochToMoment();
            const { momentTime } = parse(`+${exp}`, now);
            const diff = momentTime.diff(now, 'seconds');
            return Math.abs(diff);
        }
        return 0;
    } catch (ex) {
        return 0;
    }
};

/**
 * Control DataSource refresh behaviour
 */
export default class RefreshScheduler {
    constructor({ refreshFunc = () => {}, refreshInterval, refreshType = 'delay' }) {
        this.refreshFunc = refreshFunc;
        this.refreshType = refreshType;
        this.interval = computeInterval(refreshInterval, refreshType);
        this.stopped = false;
        this.timeout = null;
    }

    scheduleNextRefresh = () => {
        this.timeout = setTimeout(() => {
            if (!this.stopped) {
                this.refreshFunc();
            }
        }, this.interval * 1000);
    };

    stop = () => {
        if (this.timeout) {
            clearTimeout(this.timeout);
            this.timeout = null;
        }
        this.stopped = true;
    };
}
