import console from '@splunk/dashboard-utils/console';

/**
 * Base event
 */
export class DashboardLifecycleEvent {
    constructor(payload) {
        Object.assign(this, payload);
    }
}

/**
 * Cancellable event
 */
export class CancellableDashboardLifecycleEvent extends DashboardLifecycleEvent {
    constructor(...args) {
        super(...args);
        this._cancelled = false; // eslint-disable-line
    }

    preventDefault() {
        this._cancelled = true; // eslint-disable-line
    }
}

const lifecycleCallbacks = {
    onInitialize: {},
    onEventTrigger: {},
    onLinkToUrl: {},
};

function invokePluginCallbackWithEvent(plugin, name, eventGen) {
    if (plugin && name in plugin) {
        const event = eventGen();
        try {
            plugin[name](event);
        } catch (e) {
            console.error(`Caught error while invoking lifecycle plugin callback ${name}`, e);
            event.error = e;
        }
        return event;
    }
    return null;
}

export function validateDashboardPlugin(plugin) {
    if (!plugin || typeof plugin !== 'object') {
        throw new Error('Invalid lifecycle plugin provided - should be an object');
    }

    Object.keys(plugin).forEach(key => {
        if (!(key in lifecycleCallbacks)) {
            if (typeof plugin[key] === 'function') {
                console.warn(`Unknown callback function "${key}" in lifecycle plugin`);
            }
        } else if (lifecycleCallbacks[key].deprecated) {
            console.warn(`Lifecycle plugin callback function "${key}" is DEPRECATED.`);
        }
    });

    return plugin;
}

/**
 * A module wrap user provided plugin object
 */
class DashboardPluginWrapper {
    constructor(plugin) {
        this.plugin = plugin;
    }

    invokePluginCallback = (name, payload) => {
        invokePluginCallbackWithEvent(this.plugin, name, () => new DashboardLifecycleEvent(payload));
    };

    invokeCancellablePluginCallback = (name, payload) => {
        const event = invokePluginCallbackWithEvent(
            this.plugin,
            name,
            () => new CancellableDashboardLifecycleEvent(payload)
        );

        return event != null ? event._cancelled : false; // eslint-disable-line
    };
}

/**
 * DashboardPlugin Factory function, create a wrapped dashboard plugin
 * @param {*} plugin
 */
export const createDashboardPlugin = (plugin = {}) => new DashboardPluginWrapper(plugin);
