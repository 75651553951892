import { selectDefinition } from '../state/redux/definition';
import {
    createVisualization as createVisualizationAction,
    cloneVisualizations as cloneVisualizationsAction,
    removeVisualizations as removeVisualizationsAction,
    adjustVisualizationOrder as adjustVisualizationOrderAction,
} from '../state/sagas/sagaActions';
import { selectSubmittedTokens } from '../state/redux/tokens';
import { assembleSnapshot } from '../utils/snapshot';
import { toggleFullscreen } from '../state/redux/fullscreen';

export default class DashboardCoreApi {
    constructor({ store, apiRegistry, dataSourceRegistry }) {
        this.store = store;
        this.apiRegistry = apiRegistry;
        this.dataSourceRegistry = dataSourceRegistry;
    }

    /**
     * createVisualization
     * @param {*} param0
     */
    async createVisualization({
        visualizationId,
        visualizationDefinition,
        layoutItemType = 'block',
        dataSourceType,
        dataSourceDefinition,
    }) {
        this.store.dispatch(
            createVisualizationAction({
                visualizationId,
                visualizationDefinition,
                layoutItemType,
                dataSourceType,
                dataSourceDefinition,
            })
        );
    }

    /**
     * clone one or more existing visualizations
     * @method cloneVisualization
     * @param {Object} config
     * @param {Array} config.from Original VizIds
     * @param {Array} config.to Ids for cloned viz
     * @param {Number} [config.offsetMultiplier=1] Multiplier to offset cloned vis
     */
    async cloneVisualizations({ from, to, offsetMultiplier = 1 }) {
        this.store.dispatch(cloneVisualizationsAction({ from, to, offsetMultiplier }));
    }

    /**
     * remove visualizations
     * @param {*} vizIds
     */
    async removeVisualizations(vizIds) {
        this.store.dispatch(removeVisualizationsAction(vizIds));
    }

    /**
     * return visualization ids in order
     */
    getVisualizationOrder() {
        const layoutApi = this.apiRegistry.getLayoutApi();
        if (layoutApi) {
            return layoutApi.getLayoutItemOrder();
        }
        return null;
    }

    /**
     * adjust visualization order, this will produce a new definition
     */
    async adjustVisualizationOrder(fromIdx, toIdx) {
        this.store.dispatch(adjustVisualizationOrderAction(fromIdx, toIdx));
    }

    /**
     * take snapshot of current dashboard
     */
    async takeSnapshot() {
        const state = this.store.getState();
        const definition = selectDefinition(state);
        const tokens = selectSubmittedTokens(state);

        return assembleSnapshot({
            definition,
            tokens,
            apiRegistry: this.apiRegistry,
            dataSourceRegistry: this.dataSourceRegistry,
        });
    }

    /**
     * focus on a visualization
     * @param {string} vizId visualization id
     */
    focusOnVisualization(vizId) {
        const vizApi = this.apiRegistry.getVisualizationApi(vizId);
        if (vizApi && vizApi.focus) {
            vizApi.focus();
        }
    }

    toggleVisualizationFullscreen(vizId) {
        this.store.dispatch(toggleFullscreen(vizId));
    }
}

export const createDashboardApi = options => new DashboardCoreApi(options);
