/* eslint-disable no-restricted-syntax */
import get from 'lodash/get';
import { put, takeEvery, cancelled, select } from 'redux-saga/effects';
import console, { logfmt } from '@splunk/dashboard-utils/console';
import { replaceTokensForObject } from '@splunk/dashboard-utils/token';
import { isValidUrl } from '@splunk/dashboard-utils/url';
import { triggerEvent } from './sagaActions';
import { setToken, unsetToken, selectSubmittedTokens } from '../redux/tokens';
import { selectDefinition } from '../redux/definition';
import DefaultInputHandler from '../../events/DefaultInputHandler';

const defaultInputHandler = new DefaultInputHandler();
const defaultHandlers = [defaultInputHandler];

/**
 * find event handlers that can handle the given event
 * @param {*} definition
 * @param {*} componentId
 * @param {*} event
 * @param {*} preset
 */
const findEventHandler = (definition, event, preset, tokens) => {
    let handlers = [];
    if (event.targetId != null) {
        ['inputs', 'dataSources', 'visualizations'].forEach(type => {
            const matchedHandlers = get(definition, [type, event.targetId, 'eventHandlers'], [])
                .map(({ type: handlerType, options }) =>
                    // treat eventhandler as immutable module, always create new instance here
                    preset.createEventHandler(handlerType, replaceTokensForObject(options, tokens))
                )
                .filter(h => h.canHandle(event));
            handlers = handlers.concat(matchedHandlers);
        });
    }
    return handlers;
};

function* executeActions({ actions = [], plugin, event }) {
    for (const action of actions) {
        const { payload = {}, type } = action;
        let cancel;

        switch (type) {
            case 'linkTo':
                cancel = plugin.invokeCancellablePluginCallback('onLinkToUrl', {
                    dispatchedEvent: event,
                    url: payload.url,
                    newTab: payload.newTab,
                });
                if (!cancel && isValidUrl(payload.url)) {
                    if (payload.newTab) {
                        window.open(payload.url, '_blank');
                    } else {
                        window.location = payload.url;
                    }
                }
                break;
            case 'setToken':
                yield put.resolve(setToken(payload.tokens, payload.namespace, payload.submit));
                break;
            case 'unsetToken':
                yield put.resolve(unsetToken(payload.tokenName, payload.namespace, payload.submit));
                break;
            default:
        }
    }
}

function* handleEvent(sagaContext, action) {
    const definition = yield select(selectDefinition);
    const { targetId, eventType, eventPayload, eventId } = action.payload || {};
    const event = {
        targetId,
        type: eventType,
        payload: eventPayload,
        originalEvent: eventId ? sagaContext.eventRegistry.retrieveEvent(eventId) : null,
    };
    const tokens = yield select(selectSubmittedTokens);
    /**
     * for one particular event, we will execute its default handler first and then the custom handlers.
     * Custom handlers will be executed strictly follow their defined order in dashboard definition.
     */
    const matchedDefaultHandlers = defaultHandlers.filter(h => h.canHandle(event));
    const matchedCustomHandlers = findEventHandler(definition, event, sagaContext.preset, tokens);
    const handlers = matchedDefaultHandlers.concat(matchedCustomHandlers);

    // loop through every handler and execute their returned actions in a sequential way
    for (const handler of handlers) {
        const actions = yield handler.handle(event);
        yield executeActions({ actions, plugin: sagaContext.dashboardPlugin, event });
    }
    sagaContext.dashboardPlugin.invokePluginCallback('onEventTrigger', event);
}

export default function* eventSaga(sagaContext) {
    try {
        yield takeEvery(triggerEvent, handleEvent, sagaContext);
    } catch (error) {
        if (!(yield cancelled())) {
            console.error(...logfmt`Caught error: ${error}`);
        }
    } finally {
        // do nothing
    }
}
