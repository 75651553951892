import React from 'react';
import T from 'prop-types';
import Button from '@splunk/react-ui/Button';

const Submit = ({ disabled, label, onClick }) => (
    <Button disabled={disabled} label={label} appearance="primary" onClick={onClick} />
);

Submit.propTypes = {
    disabled: T.bool,
    label: T.string,
    /**
     * A callback to trigger event
     */
    onClick: T.func,
};
Submit.defaultProps = {
    onClick: () => {},
    label: 'Submit',
    disabled: false,
};

export default Submit;
