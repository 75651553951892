import isNumber from 'lodash/isNumber';

/**
 * convert number to px
 * @param {*} number
 */
export const toPx = (number, defaultNumber = 0) =>
    isNumber(number) ? `${number}px` : number || toPx(defaultNumber);

/**
 * generate width/height css properties
 * @param {*} param0
 */
export const toDimension = ({ width, height }) => {
    let css = '';
    if (width != null) {
        css = `
        width: ${toPx(width)};
        `;
    }
    if (height != null) {
        css = `
        ${css}
        height: ${toPx(height)};
        `;
    }
    return css;
};

/**
 * generate margin css property
 */
export const arrayToCSSProp = (prop, vals = [0, 0, 0, 0]) => {
    if (Array.isArray(vals)) {
        return `
            ${prop}: ${toPx(vals[0])} ${toPx(vals[1])} ${toPx(vals[2])} ${toPx(vals[3])};
        `;
    }

    return `${prop}: ${toPx(vals)};`;
};

/**
 * generate margin css property
 */
export const toMargin = (margins = [0, 0, 0, 0]) => arrayToCSSProp('margin', margins);

/**
 * generate padding css property
 */
export const toPadding = (paddings = [0, 0, 0, 0]) => arrayToCSSProp('padding', paddings);
