import { _ } from '@splunk/ui-utils/i18n';

const u = {};
/* eslint-disable no-multi-assign */
u.s = u.sec = u.secs = u.second = u.seconds = {
    abbr: 's',
    singular: _('second'),
    plural: _('seconds'),
};
u.m = u.min = u.mins = u.minute = u.minutes = {
    abbr: 'm',
    singular: _('minute'),
    plural: _('minutes'),
};
u.h = u.hr = u.hrs = u.hour = u.hours = { abbr: 'h', singular: _('hour'), plural: _('hours') };
u.d = u.day = u.days = { abbr: 'd', singular: _('day'), plural: _('days') };
u.w = u.week = u.weeks = { abbr: 'w', singular: _('week'), plural: _('weeks') };
u.mon = u.month = u.months = { abbr: 'mon', singular: _('month'), plural: _('months') };
u.q = u.qtr = u.qtrs = u.quarter = u.quarters = {
    abbr: 'q',
    singular: _('quarter'),
    plural: _('quarters'),
};
u.y = u.yr = u.yrs = u.year = u.years = { abbr: 'y', singular: _('year'), plural: _('years') };
/* eslint-enable no-multi-assign */

export const timeUnits = u;

// snap units can additionally include days of the week.
export const snapUnits = { ...u };
snapUnits.w0 = { abbr: 'w0', singular: _('Sunday'), plural: _('Sundays') };
snapUnits.w1 = { abbr: 'w1', singular: _('Monday'), plural: _('Mondays') };
snapUnits.w2 = { abbr: 'w2', singular: _('Tuesday'), plural: _('Tuesdays') };
snapUnits.w3 = { abbr: 'w3', singular: _('Wednesday'), plural: _('Wednesdays') };
snapUnits.w4 = { abbr: 'w4', singular: _('Thursday'), plural: _('Thursdays') };
snapUnits.w5 = { abbr: 'w5', singular: _('Friday'), plural: _('Fridays') };
snapUnits.w6 = { abbr: 'w6', singular: _('Saturday'), plural: _('Saturdays') };
snapUnits.w7 = { abbr: 'w7', singular: _('Sunday'), plural: _('Sundays') };
