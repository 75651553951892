import isFunction from 'lodash';
import React, { PureComponent } from 'react';
import T from 'prop-types';
import ResizeObserver from 'resize-observer-polyfill';

const styles = {
    position: 'absolute',
    width: 0,
    height: 0,
    visibility: 'hidden',
    display: 'none',
};

class SizeAwareWrapper extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            width: null,
            height: null,
        };
        this.animationFrameID = null;
        this.ro = new ResizeObserver(this.handleResize);
    }

    componentDidMount() {
        const resizableElement = this.getElement();
        if (resizableElement) {
            this.ro.observe(resizableElement);
        }
    }

    componentWillUnmount() {
        const resizableElement = this.getElement();
        if (resizableElement) {
            this.ro.unobserve(resizableElement);
        }
        if (window && this.animationFrameID) {
            window.cancelAnimationFrame(this.animationFrameID);
        }
    }

    getElement = () => {
        // it listens on the size of its parent elemnt
        const parentElement = this.el && this.el.parentElement;
        return parentElement;
    };

    handleResize = entries => {
        entries.forEach(entry => {
            const { width, height } = entry.contentRect;
            // use requestAnimationFrame to make sure width/height is updated in time
            this.animationFrameID = window.requestAnimationFrame(() => {
                this.setState({ width, height });
            });
        });
    };

    renderChildren = () => {
        // expect children to be a function
        const { width, height } = this.state;
        const { children } = this.props;
        if (width != null && height != null && isFunction(children)) {
            return children({ width, height });
        }
        return null;
    };

    handleRef = el => {
        this.el = el;
    };

    render() {
        return (
            <React.Fragment>
                <div key="resize-detector" style={styles} ref={this.handleRef} />
                {this.renderChildren()}
            </React.Fragment>
        );
    }
}

SizeAwareWrapper.propTypes = {
    children: T.any, // eslint-disable-line react/forbid-prop-types
};

SizeAwareWrapper.defaultProps = {
    children: null,
};

export default SizeAwareWrapper;
