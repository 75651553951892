/* eslint-disable class-methods-use-this */
class DefaultInputHandler {
    canHandle({ type }) {
        return type === 'input.change';
    }

    handle({ payload }) {
        const { tokens, tokenNamespace = 'default', submit = true } = payload;
        return Promise.resolve([
            { type: 'setToken', payload: { tokens, namespace: tokenNamespace, submit } },
        ]);
    }
}

export default DefaultInputHandler;
