import values from 'lodash/values';
import pick from 'lodash/pick';
import { createAction, handleActions } from 'redux-actions';

/**
 * Actions
 */
export const updateDefinition = createAction('UPDATE_DEFINITION');
export const addVisualization = createAction('ADD_VISUALIZATION', (vizId, vizDef, dsId, dsDef) => ({
    vizId,
    vizDef,
    dsId,
    dsDef,
}));
export const updateVizOptions = createAction('UPDATE_VIZ_OPTIONS', (id, options) => ({
    id,
    options,
}));
export const updateVizTitle = createAction('UPDATE_VIZ_TITLE', (id, title) => ({
    id,
    title,
}));
export const updateVizDescription = createAction('UPDATE_VIZ_DESCRIPTION', (id, description) => ({
    id,
    description,
}));
export const updateLayoutOptions = createAction('UPDATE_LAYOUT_OPTIONS');
export const updateLayoutStructure = createAction('UPDATE_LAYOUT_STRUCTURE');
/**
 * Selectors
 */
export const selectDefinition = state => state.definition;
export const selectLayout = state => selectDefinition(state).layout;
export const selectDataSources = state => selectDefinition(state).dataSources;
export const selectDataSource = (state, dsId) => selectDataSources(state)[dsId];
export const selectVisualizations = state => selectDefinition(state).visualizations;
export const selectVisualization = (state, vizId) => selectVisualizations(state)[vizId];
export const selectInputs = state => selectDefinition(state).inputs;
export const selectInput = (state, inputId) => selectInputs(state)[inputId];
export const selectTitle = state => selectDefinition(state).title;

export const selectDataSourcesForViz = (state, id) => {
    const viz = selectVisualization(state, id);
    const dataSourceIds = values(viz.dataSources || {});
    return pick(selectDataSources(state), dataSourceIds);
};

export const selectDataSourcesForInput = (state, id) => {
    const input = selectInput(state, id);
    const dataSourceIds = values(input.dataSources || {});
    return pick(selectDataSources(state), dataSourceIds);
};
/**
 * Reducer
 */

export default handleActions(
    {
        [updateDefinition]: (state, { payload }) => payload,
        [updateVizOptions]: (state, { payload: { id, options: newOptions } }) => {
            const nextDefinition = {
                ...state,
                visualizations: {
                    ...state.visualizations,
                    [id]: {
                        ...state.visualizations[id],
                        options: {
                            ...state.visualizations[id].options,
                            ...newOptions,
                        },
                    },
                },
            };
            return nextDefinition;
        },
        [updateVizTitle]: (state, { payload: { id, title: newTitle } }) => {
            const trimmedTitle = newTitle && newTitle.trim();
            if (!trimmedTitle) {
                const nextDefinition = {
                    ...state,
                };
                delete nextDefinition.visualizations[id].title;
                return nextDefinition;
            }
            return {
                ...state,
                visualizations: {
                    ...state.visualizations,
                    [id]: {
                        ...state.visualizations[id],
                        title: trimmedTitle,
                    },
                },
            };
        },
        [updateVizDescription]: (state, { payload: { id, description: newDescription } }) => {
            const trimmedDescription = newDescription && newDescription.trim();
            if (!trimmedDescription) {
                const nextDefinition = {
                    ...state,
                };
                delete nextDefinition.visualizations[id].description;
                return nextDefinition;
            }
            return {
                ...state,
                visualizations: {
                    ...state.visualizations,
                    [id]: {
                        ...state.visualizations[id],
                        description: trimmedDescription,
                    },
                },
            };
        },
        [updateLayoutStructure]: (state, { payload: layoutStructure }) => ({
            ...state,
            layout: {
                ...state.layout,
                structure: layoutStructure,
            },
        }),
    },
    {}
);
