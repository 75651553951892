import { some, isString, escape, mapValues, isFunction, get, map, isPlainObject, isArray } from 'lodash';

const TOKEN_NAMESPACE_PREFIX_PATTERN = /(\w+:)?/.source;
// ex: token.name|suh               (old-style filter chain)
// ex: token.name|lower|capitalize  (new-style filter chain)
const TOKEN_NAME_CHARS_PATTERN = /([^$|:]+?)(\|[|\w]+)?/.source;
// ex: $ns:token.name|suh$
const TOKEN_PATTERN = /\$/.source + TOKEN_NAMESPACE_PREFIX_PATTERN + TOKEN_NAME_CHARS_PATTERN + /\$/.source;
const TOKEN_OR_DOLLAR_RE = new RegExp(`${TOKEN_PATTERN}|${/\$\$/.source}`, 'g');

const TokenRegExp = new RegExp(TOKEN_PATTERN);

const r = regexp => {
    let flags = '';
    if (regexp.global) {
        flags += 'g';
    }
    if (regexp.multiline) {
        flags += 'm';
    }
    if (regexp.ignoreCase) {
        flags += 'i';
    }
    return new RegExp(regexp.source, flags);
};

const VALUE_ESCAPERS = {
    search(v) {
        return JSON.stringify(String(v));
    },
    url(v) {
        return encodeURIComponent(String(v));
    },
    html(v) {
        return escape(String(v));
    },
    noEscape(v) {
        return v;
    },
};

export const DEFAULT_FILTERS = {
    h: VALUE_ESCAPERS.html,
    u: VALUE_ESCAPERS.url,
    s: VALUE_ESCAPERS.search,
    n: VALUE_ESCAPERS.noEscape,
};

/**
 * extract tokens from value
 * @param {*} value
 */
export const extractTokens = value => {
    /*
     * Looks for:
     *     (1a) $$ (literal $)
     *     (1b) $ns:token.name|suh$ (tokens)
     */
    const tokens = [];
    const rex = r(TOKEN_OR_DOLLAR_RE);
    let match = rex.exec(value);
    while (match) {
        const tokenNamespace = match[1];
        const tokenName = match[2];
        const filterChain = match[3];

        const namespace = tokenNamespace ? tokenNamespace.substring(0, tokenNamespace.length - 1) : 'default';

        const filters = filterChain ? filterChain.substring(1).split('|') : [];

        tokens.push({
            namespace,
            name: tokenName,
            filters,
        });
        match = rex.exec(value);
    }
    return tokens;
};
/**
 * Replace a single string with tokens
 * @param {*} value
 * @param {*} tokens
 * @param {*} tokenFilters
 */
export const replaceTokens = (value, tokens = {}, tokenFilters = DEFAULT_FILTERS) => {
    if (value == null) {
        return value;
    }
    return value.replace(r(TOKEN_OR_DOLLAR_RE), (match, tokenNamespace, tokenName, filterChain) => {
        let namespace = 'default';
        if (tokenNamespace) {
            namespace = tokenNamespace.substring(0, tokenNamespace.length - 1);
        }
        let v = match;
        const tokenValue = get(tokens, [namespace, tokenName], null);
        if (tokenValue != null) {
            v = tokenValue;
            const filters = filterChain ? filterChain.substring(1).split('|') : [];
            // apply token filters
            filters.forEach(f => {
                if (isFunction(tokenFilters[f])) {
                    v = tokenFilters[f](v);
                }
            });
        }
        return v;
    });
};

const MAX_RECURSION_LEVEL = 10;

/**
 * Replace tokens in an object's value
 * @param {*} value
 * @param {*} tokens
 * @param {*} tokenFilters
 */
export const replaceTokensForObject = (
    value,
    tokens = {},
    tokenFilters = DEFAULT_FILTERS,
    recursionLevel = 0
) => {
    if (value == null) {
        return value;
    }
    if (isString(value)) {
        return replaceTokens(value, tokens, tokenFilters);
    }
    if (isPlainObject(value) && recursionLevel <= MAX_RECURSION_LEVEL) {
        return mapValues(value, v => replaceTokensForObject(v, tokens, tokenFilters, recursionLevel + 1));
    }
    if (isArray(value) && recursionLevel <= MAX_RECURSION_LEVEL) {
        return map(value, v => replaceTokensForObject(v, tokens, tokenFilters, recursionLevel + 1));
    }
    return value;
};

/**
 * test if a string contains a token
 * @param {String} value
 */
export const hasTokens = value => typeof value === 'string' && TokenRegExp.test(value);
/**
 * test if a object value contains a token
 * @param {Object} obj
 */
export const hasTokensInObject = (obj, recursionLevel = 0) => {
    if (obj == null) {
        return false;
    }
    if (isString(obj)) {
        return hasTokens(obj);
    }
    if ((isArray(obj) || isPlainObject(obj)) && recursionLevel <= MAX_RECURSION_LEVEL) {
        return some(obj, v => hasTokensInObject(v, recursionLevel + 1));
    }
    return false;
};
