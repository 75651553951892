import { createAction, handleActions } from 'redux-actions';

export const setGlobalMessage = createAction('SET_GLOBAL_MESSAGE');

export default handleActions(
    {
        [setGlobalMessage]: (state, { payload }) => payload,
    },
    null
);
