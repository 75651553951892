import suiEnterprise from '@splunk/themes/enterprise';
import suiEnterpriseDark from '@splunk/themes/enterpriseDark';
import scpTheme from '@splunk/themes/scp';

// we may provide our dashboard specific variables here.

export const enterprise = {
    ...suiEnterprise,
    headerTextColor: suiEnterprise.textColor,
    colorPickerBorderColor: suiEnterprise.gray80,
    dashboardBackgroundColor: suiEnterprise.gray96,
    panelBackgroundColor: suiEnterprise.backgroundColor,
    layoutItemBgColor: suiEnterprise.backgroundColor,
    layoutMenuBgColor: suiEnterprise.backgroundColor,
    layoutMenuHoverBgColor: suiEnterprise.backgroundColorHover,
    layoutBackgroundColor: suiEnterprise.gray96,
    inputHoverBackgroundColor: suiEnterprise.gray96,
};
export const enterpriseDark = {
    ...suiEnterpriseDark,
    headerTextColor: suiEnterpriseDark.textColor,
    colorPickerBorderColor: suiEnterpriseDark.gray20,
    dashboardBackgroundColor: suiEnterpriseDark.gray30,
    panelBackgroundColor: suiEnterpriseDark.backgroundColor,
    layoutItemBgColor: suiEnterpriseDark.backgroundColor,
    layoutMenuBgColor: suiEnterpriseDark.backgroundColor,
    layoutMenuHoverBgColor: suiEnterpriseDark.backgroundColorHover,
    layoutBackgroundColor: suiEnterpriseDark.gray30,
    inputHoverBackgroundColor: suiEnterpriseDark.gray45,
};
export const scp = {
    ...scpTheme,
    headerTextColor: scpTheme.gray96, // use white 2 for heading
    colorPickerBorderColor: scpTheme.gray20,
    dashboardBackgroundColor: scpTheme.backgroundColor,
    panelBackgroundColor: scpTheme.gray17,
    popoverBackgroundColor: scpTheme.gray20,
    layoutItemBgColor: scpTheme.backgroundColor,
    layoutMenuBgColor: scpTheme.backgroundColor,
    layoutMenuHoverBgColor: scpTheme.backgroundColorHover,
    layoutBackgroundColor: scpTheme.backgroundColor,
    inputHoverBackgroundColor: scpTheme.gray17,
};
