import { Observable } from 'rxjs/Observable';
import get from 'lodash/get';
import each from 'lodash/each';
import once from 'lodash/once';
import Subscription from './Subscription';
import RefreshScheduler, { parseExprToSeconds } from './RefreshScheduler';

/**
 * A control wrapper on top of actual DataSource instance
 */
class DataSourceController {
    /**
     *
     * @param {String} id DataSource Id
     * @param {Object} dataSourceDef DataSource definition
     * @param {Object} dataSourceContext DataSource context
     * @param {Object} preset dashboard preset
     */
    constructor({ id, dataSourceDef, dataSourceContext, preset }) {
        this.id = id;
        this.def = dataSourceDef;
        this.preset = preset;
        this.context = dataSourceContext;
        this.setupOnce = once(this.setup);
        // subscriptions
        this.subs = {};
        this.setupAutoRefresh();
    }

    /**
     * setup DataSource auto refresh
     */
    setupAutoRefresh() {
        const refresh = get(this.def, 'options.refresh');
        const refreshType = get(this.def, 'options.refreshType');
        if (refresh) {
            const refreshInterval = parseExprToSeconds(refresh);
            if (refreshInterval) {
                this.scheduler = new RefreshScheduler({
                    refreshFunc: this.refresh,
                    refreshInterval,
                    refreshType,
                });
            }
        }
    }

    /**
     * return existing subscriptions
     * @returns {Object} subscriptions
     */
    getSubscriptions = () => {
        return this.subs;
    };

    /**
     * setup DataSource
     */
    setup = async () => {
        try {
            this.dataSource = this.preset.createDataSource(
                this.def.type,
                this.def.options,
                this.context,
                this.def.meta,
                this.id
            );
            this.setupError = null;
            await this.dataSource.setup();
            this.broadcast({
                eventType: 'datasource.setup',
                targetId: this.id,
                payload: {},
            });
        } catch (ex) {
            this.setupError = ex.message;
        }
    };

    /**
     * teardown DataSource
     */
    teardown = async () => {
        try {
            await this.dataSource.teardown();
            this.broadcast({
                eventType: 'datasource.teardown',
                targetId: this.id,
                payload: {},
            });
        } catch (ex) {
            // ignore teardown error
        }
    };

    /**
     * refresh the current DataSource
     */
    refresh = async () => {
        try {
            // we need to wait till teardown completed here.
            await this.teardown();
            await this.setup();
        } catch (ex) {
            this.setupError = ex.message;
        } finally {
            // refresh all subscriptions
            each(this.subs, sub => {
                sub.refresh();
            });
        }
    };

    /**
     * broadcast a DataSource event
     * @param {Object} event DataSource lifecycle event
     */
    broadcast = event => {
        if (this.dataSourceEventCallback) {
            this.dataSourceEventCallback(event);
        }
    };

    /**
     * register teardown callback
     * @param {Function} teardownCallback teardown callback
     */
    onTeardown = teardownCallback => {
        this.teardownCallback = teardownCallback;
    };

    /**
     * register DataSource event callback
     * @param {Function} eventCallback callback
     */
    onDataSourceEvent = eventCallback => {
        this.dataSourceEventCallback = eventCallback;
    };

    /**
     * handle subscription cancel
     * @param {String} consumerId DataSource consumerId, this will either be viz or input id
     */
    handleSubscriptionCancel = consumerId => {
        delete this.subs[consumerId];
        // teardown datasource when no consumers
        if (Object.keys(this.subs).length === 0) {
            // we don't need to wait till teardown complete
            this.teardown();
            if (this.scheduler) {
                this.scheduler.stop();
            }
            if (this.teardownCallback) {
                this.teardownCallback();
            }
        }
    };

    handleSubscriptionStart = () => {
        if (this.scheduler && this.scheduler.refreshType === 'interval') {
            this.scheduler.scheduleNextRefresh();
        }
    };

    handleSubscriptionComplete = () => {
        if (this.scheduler && this.scheduler.refreshType === 'delay') {
            this.scheduler.scheduleNextRefresh();
        }
    };

    /**
     * request a new dataset
     * @param {Object} requestParams requestParams
     * @param {String} consumerId DataSource consumerId, this will either be viz or input id
     * @returns {Observable} Observable instance that will emit data or error
     */
    request = (requestParams, consumerId) => {
        const result = this.dataSource.request(requestParams, consumerId);
        return typeof result.subscribe === 'function' ? result : Observable.create(result);
    };

    /**
     * create a new DataSource subscription
     * @param {String} consumerId DataSource consumerId, this will either be viz or input id
     * @param {Object} initialRequestParams initial requestParams
     * @returns {Subscription} DataSource subscription
     */
    subscribe = async ({ consumerId, initialRequestParams }) => {
        // always try to call setup first
        await this.setupOnce();
        const sub = new Subscription(this, consumerId, initialRequestParams);
        this.subs[consumerId] = sub;
        sub.onStart(this.handleSubscriptionStart);
        sub.onCancel(this.handleSubscriptionCancel);
        sub.onComplete(this.handleSubscriptionComplete);
        return sub;
    };
}

export default DataSourceController;
