import { createAction, handleActions } from 'redux-actions';

export const updateSelectedVisualizations = createAction('UPDATE_SELECTED_VISUALIZATIONS');

export const selectEditor = state => state.editor;
export const selectSelectedVisualizations = state => selectEditor(state).selectedVisualizations || [];

export default handleActions(
    {
        [updateSelectedVisualizations]: (state, { payload: selectVizs }) => ({
            ...state,
            selectedVisualizations: selectVizs,
        }),
    },
    {
        selectedVisualizations: [],
    }
);
