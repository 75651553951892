/* eslint-disable no-restricted-syntax */
import each from 'lodash/each';
import isFunction from 'lodash/isFunction';
import { put, call, takeEvery, cancelled, select } from 'redux-saga/effects';
import console, { logfmt } from '@splunk/dashboard-utils/console';
import DashboardDefinition from '@splunk/dashboard-definition/DashboardDefinition';
import {
    createVisualization,
    cloneVisualizations,
    removeVisualizations,
    adjustVisualizationOrder,
} from './sagaActions';
import { selectDefinition, updateDefinition } from '../redux/definition';

/**
 * create visualization and datasource
 * @param {*} sagaContext
 * @param {*} action
 */
function* handleCreateVisualization(sagaContext, action) {
    const layoutApi = sagaContext.apiRegistry.getLayoutApi();
    if (layoutApi && isFunction(layoutApi.addLayoutItem)) {
        const currentDefinition = yield select(selectDefinition);
        const definition = DashboardDefinition.fromJSON(currentDefinition);
        const {
            visualizationId,
            visualizationDefinition,
            layoutItemType,
            dataSourceType,
            dataSourceDefinition,
        } = action.payload || {};
        // fetch viz preset
        const viz = sagaContext.preset.findVisualization(visualizationDefinition.type);
        const vizContract = (viz && viz.vizContract) || {};
        // add datasource and connect it with visualization if provided
        if (visualizationId && visualizationDefinition) {
            const vizDef = { ...visualizationDefinition };
            if (dataSourceType && dataSourceDefinition) {
                const dataSourceId = definition.nextDataSourceId();
                definition.addDataSource(dataSourceId, dataSourceDefinition);
                vizDef.dataSources = {
                    ...(vizDef.dataSources || {}),
                    [dataSourceType]: dataSourceId,
                };
            }
            const proposedLayoutStructure = yield call(
                layoutApi.addLayoutItem,
                visualizationId,
                vizContract,
                layoutItemType
            );
            definition
                .addVisualization(visualizationId, vizDef)
                .updateLayoutStructure(proposedLayoutStructure);
        }
        // onDefinitionChange callback will then be invoke
        yield put(updateDefinition(definition.toJSON()));
    }
}

export function* handleCloneVisualizations(sagaContext, action) {
    const layoutApi = sagaContext.apiRegistry.getLayoutApi();
    if (layoutApi && isFunction(layoutApi.addLayoutItem)) {
        const currentDefinition = yield select(selectDefinition);
        const definition = DashboardDefinition.fromJSON(currentDefinition);
        const { from, to, offsetMultiplier } = action.payload || {};
        if (from.length) {
            each(from, (vizId, iter) => {
                definition.cloneVisualization(vizId, to[iter]);
            });
            const proposedLayoutStructure = yield call(layoutApi.cloneLayoutItems, {
                from,
                to,
                offsetMultiplier,
            });
            definition.updateLayoutStructure(proposedLayoutStructure);
        }
        yield put(updateDefinition(definition.toJSON()));
    }
}

function* handleRemoveVisualizations(sagaContext, action) {
    const layoutApi = sagaContext.apiRegistry.getLayoutApi();
    if (layoutApi && isFunction(layoutApi.removeLayoutItems)) {
        const currentDefinition = yield select(selectDefinition);
        const definition = DashboardDefinition.fromJSON(currentDefinition);
        const vizIds = action.payload || {};
        const proposedLayoutStructure = yield call(layoutApi.removeLayoutItems, vizIds);
        each(vizIds, id => {
            definition.removeVisualization(id);
        });
        // onDefinitionChange callback will then be invoke
        definition.updateLayoutStructure(proposedLayoutStructure);
        yield put(updateDefinition(definition.toJSON()));
    }
}

function* handleVisualizationOrder(sagaContext, action) {
    const layoutApi = sagaContext.apiRegistry.getLayoutApi();
    if (layoutApi && layoutApi.adjustLayoutItemOrder) {
        const currentDefinition = yield select(selectDefinition);
        const definition = DashboardDefinition.fromJSON(currentDefinition);
        const proposedLayoutStructure = yield call(
            layoutApi.adjustLayoutItemOrder,
            action.payload.from,
            action.payload.to
        );
        // onDefinitionChange callback will then be invoke
        definition.updateLayoutStructure(proposedLayoutStructure);
        yield put(updateDefinition(definition.toJSON()));
    }
}

export default function* editingSaga(sagaContext) {
    try {
        yield takeEvery(createVisualization, handleCreateVisualization, sagaContext);
        yield takeEvery(cloneVisualizations, handleCloneVisualizations, sagaContext);
        yield takeEvery(removeVisualizations, handleRemoveVisualizations, sagaContext);
        yield takeEvery(adjustVisualizationOrder, handleVisualizationOrder, sagaContext);
    } catch (error) {
        if (!(yield cancelled())) {
            console.error(...logfmt`Caught error: ${error}`);
        }
    } finally {
        // do nothing
    }
}
