/**
 * Helper to store dom event emitted from visualization or input since we don't
 * want to send them as part of a redux action payload.
 */
export default class EventRegistry {
    constructor() {
        this.teardown();
    }

    registerEvent(e) {
        // eslint-disable-next-line no-plusplus
        const id = `event${++this.idSeq}`;
        this.storedEvents[id] = e;
        return id;
    }

    retrieveEvent(id) {
        const event = this.storedEvents[id];
        delete this.storedEvents[id];
        return event;
    }

    teardown() {
        this.storedEvents = {};
        this.idSeq = 0;
    }
}
