import get from 'lodash/get';
import defaultsDeep from 'lodash/defaultsDeep';
import { ref } from '@splunk/react-ui/themes';
import { enterprise, enterpriseDark } from './variables';

/**
 * mixins
 */
const mixins = {
    enterprise: enterprise.mixins,
    enterpriseDark: enterpriseDark.mixins,
};

/**
 * A runtime theme registry
 */
class ThemeRegistry {
    constructor(...modules) {
        this.module = modules.join('/');
        this.defaultTheme = 'enterprise';
        this.themes = {
            enterprise: {
                _name: 'enterprise', // theme name
                _mixins: mixins.enterprise, // mixins
            },
        };
    }

    /**
     * Add default theme definition
     * @param {*} theme theme name
     * @param {*} themeDefinition theme definition
     */
    addDefaultTheme(theme, themeDefinition) {
        this.addTheme(theme, themeDefinition);
        this.defaultTheme = theme;
    }

    /**
     * Add a new theme definition
     * @param {*} theme theme name
     * @param {*} themeDefinition theme definition
     */
    addTheme(theme, themeDefinition) {
        this.themes[theme] = {
            _name: theme, // theme name
            _mixins: mixins[theme],
            [this.module]: themeDefinition,
        };
    }

    /**
     * retrieve a theming variable
     */
    themeVariable(themeKey) {
        return props => {
            const val = get(props, ['theme', this.module, themeKey]);
            if (val == null) {
                return get(this.themes, [this.defaultTheme, this.module, themeKey], '');
            }
            return val;
        };
    }

    /**
     * retrieve the mixin function
     * @param {*} name mixin name
     */
    mixin(name) {
        return (...args) => props => {
            let mixinFunc = get(props, ['theme', '_mixins', name]);
            if (mixinFunc == null) {
                mixinFunc = get(this.themes, [this.defaultTheme, '_mixins', name], () => {});
            }
            return mixinFunc(...args);
        };
    }

    /**
     * export theme definition
     */
    toThemes() {
        return this.themes;
    }
}

/**
 * create a new theme registry
 * @param {*} packageName
 * @param  {...any} modules
 */
export const createThemeRegistry = (packageName, ...modules) => new ThemeRegistry(packageName, ...modules);
/**
 * merge multiple themes
 */
export const mergeThemes = (...themes) => defaultsDeep({}, ...themes);

/**
 * a helper function that get ref from styled component
 */
export const scRef = ref;
