import React, { PureComponent } from 'react';
import T from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import DataSourceConsumer from '../components/DataSourceConsumer';
import { selectInput, selectDataSourcesForInput } from '../state/redux/definition';
import { selectSubmittedTokens } from '../state/redux/tokens';
import { selectInputValue } from '../state/redux/state/input';
import { replaceTokenForDataSources } from '../utils/token';
import { inputValueChanged } from '../state/sagas/sagaActions';
import { findInitialRequestParams } from '../utils/datasource';

const Wrapper = styled.div`
    display: inline-block;
    margin-right: 8px;
`;

const mapStateToProps = (state, { id }) => ({
    inputDef: selectInput(state, id),
    value: selectInputValue(state, id),
    dataSourceDefs: replaceTokenForDataSources(
        selectDataSourcesForInput(state, id),
        selectSubmittedTokens(state)
    ),
});

const mapDispatchToProps = (dispatch, { id }) => ({
    onValueChange: (eventId, newValue) => {
        dispatch(inputValueChanged(id, newValue, eventId));
    },
});

/**
 * A input container that renders the actual input
 */
class InputContainer extends PureComponent {
    constructor(props, context) {
        super(props);
        this.apiRegistry = context.apiRegistry;
    }

    componentWillUnmount() {
        this.apiRegistry.removeInputApi(this.props.id);
    }

    registerApi = ref => {
        this.apiRegistry.registerInputApi(this.props.id, ref);
    };

    renderInput({ loading, dataSources }) {
        const { id, inputDef, value } = this.props;
        const { preset, eventRegistry } = this.context;
        if (inputDef == null) {
            return null;
        }
        const { type, title, options = {}, encoding = {} } = inputDef;
        const inputProps = {
            id,
            key: id,
            title,
            options,
            dataSources,
            encoding,
            value,
            loading,
            onValueChange: (e, newValue) => {
                const eventId = eventRegistry.registerEvent(e);
                this.props.onValueChange(eventId, newValue);
            },
            inputApiRef: this.registerApi,
        };

        // Wrap the input to break internal margins, and set our own
        return <Wrapper>{preset.createInput(type, inputProps)}</Wrapper>;
    }

    render() {
        const {
            id,
            inputDef: { type, options = {}, dataSources: dataSourceBindings = {} },
            dataSourceDefs,
        } = this.props;
        const { preset } = this.context;
        // input only support primary DataSource
        const primaryDataSourceId = dataSourceBindings.primary;
        let primaryDataSource;
        if (primaryDataSourceId) {
            primaryDataSource = dataSourceDefs[primaryDataSourceId];
        }
        return primaryDataSource ? (
            <DataSourceConsumer
                consumerId={id}
                initialRequestParams={findInitialRequestParams({
                    bindingType: 'primary',
                    consumerModule: preset.findInput(type),
                    options,
                })}
                bindingType="primary"
                dataSource={primaryDataSource}
                dataSourceId={primaryDataSourceId}
            >
                {({ loading, requestParams, data, meta, error }) => {
                    return this.renderInput({
                        loading,
                        dataSources: {
                            primary: {
                                requestParams,
                                data,
                                meta,
                                error,
                            },
                        },
                    });
                }}
            </DataSourceConsumer>
        ) : (
            this.renderInput({
                loading: false,
                dataSources: {},
            })
        );
    }
}

InputContainer.propTypes = {
    id: T.string,
    value: T.string,
    inputDef: T.object,
    dataSourceDefs: T.object,
    onValueChange: T.func,
};

InputContainer.contextTypes = {
    preset: T.object,
    eventRegistry: T.object,
    apiRegistry: T.object,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InputContainer);
