import { formatData } from '../utils/datasource';

/**
 * A DataSource subscription
 * This represent a DataSource -- Consumer binding.
 * Be aware that the binding can be n to n.
 */
class Subscription {
    /**
     *
     * @param {Object} dataSource DataSource controller
     * @param {String} consumerId Consumer id
     * @param {Object} initialRequestParams initial requestParams
     */
    constructor(dataSource, consumerId, initialRequestParams) {
        this.consumerId = consumerId;
        this.dataSource = dataSource;
        this.requestParams = initialRequestParams;
        this.lastState = {};
    }

    /**
     * get last state of this subscription
     * @returns {Object} last state, either be data or error
     */
    getLastState = () => {
        return this.lastState;
    };

    /**
     * start polling data
     */
    startPolling() {
        this.start();
        if (this.dataSource.setupError) {
            this.error({
                level: 'error',
                message: this.dataSource.setupError,
            });
            return;
        }
        try {
            const ob = this.dataSource.request(this.requestParams, this.consumerId);
            this.sub = ob.subscribe(
                ({ data, meta = {} }) => {
                    const formattedData = formatData(data);
                    if (formattedData != null) {
                        const payload = {
                            requestParams: this.requestParams,
                            data: formattedData,
                            meta,
                        };
                        this.lastState = payload;
                        this.progress(payload);
                    } else {
                        this.error({
                            level: 'error',
                            message: `${this.dataSource.def.type} returns invalid data`,
                        });
                    }
                },
                ({ level, message }) => {
                    const payload = {
                        level,
                        message,
                    };
                    this.lastState = payload;
                    this.error(payload);
                },
                () => {
                    this.done();
                }
            );
        } catch (ex) {
            this.error({
                level: 'error',
                message: ex.message,
            });
        }
    }

    /**
     * cancel this subscription
     */
    cancel() {
        if (this.sub) {
            this.sub.unsubscribe();
        }
        this.dataCallback = null;
        this.errorCallback = null;
        this.lastState = {};
        if (this.cancelCallback) {
            this.cancelCallback(this.consumerId);
        }
    }

    /**
     * refresh current subscription
     */
    refresh() {
        if (this.sub) {
            this.sub.unsubscribe();
        }
        this.startPolling();
    }

    /**
     *
     */
    start() {
        if (this.startCallback) {
            this.startCallback(this.consumerId);
        }
    }

    /**
     * send back data and broadcast progress event
     */
    progress = payload => {
        if (this.dataCallback) {
            this.dataCallback(payload);
        }
        this.dataSource.broadcast({
            eventType: 'datasource.progress',
            targetId: this.dataSource.id,
            payload: {
                consumerId: this.consumerId,
                ...payload,
            },
        });
    };

    /**
     * send back error and broadcast error event
     */
    error = payload => {
        if (this.errorCallback) {
            this.errorCallback(payload);
        }
        this.dataSource.broadcast({
            eventType: 'datasource.error',
            targetId: this.dataSource.id,
            payload: {
                consumerId: this.consumerId,
                requestParams: this.requestParams,
                error: payload,
            },
        });
        if (this.completeCallback) {
            this.completeCallback(this.consumerId);
        }
    };

    /**
     * broadcast done event
     */
    done = () => {
        this.dataSource.broadcast({
            eventType: 'datasource.done',
            targetId: this.dataSource.id,
            payload: {
                consumerId: this.consumerId,
                // we send back the last state part of done event
                ...this.lastState,
            },
        });
        if (this.completeCallback) {
            this.completeCallback(this.consumerId);
        }
    };

    /**
     * register subscription start callback
     * @param {Function} callback
     */
    onStart(callback) {
        this.startCallback = callback;
    }

    /**
     * register subscription cancel callback
     * @param {Function} callback cancel callback
     */
    onCancel(callback) {
        this.cancelCallback = callback;
    }

    /**
     * register complete callback
     * @param {Function} callback
     */
    onComplete(callback) {
        this.completeCallback = callback;
    }

    /**
     * Subscribe to DataSource data
     * @param {Function} onData data callback
     * @param {Function} onError error callback
     */
    subscribeToData({ onData = () => {}, onError = () => {} } = {}) {
        this.dataCallback = onData;
        this.errorCallback = onError;
        this.startPolling();
    }

    /**
     * update request params for this subscription
     * @param {Object} newRequestParams new request params
     */
    updateRequestParams(newRequestParams) {
        if (this.sub) {
            this.sub.unsubscribe();
        }
        this.requestParams = newRequestParams;
        this.startPolling();
    }
}

export default Subscription;
