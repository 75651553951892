/* eslint-disable class-methods-use-this */
import DataSet from '@splunk/datasource-utils/DataSet';
import DataSource from './DataSource';

export default class SnapshotDataSource extends DataSource {
    /**
     *
     * @param {Object} options.data static data set
     * @param {Number} options.delay
     * @param {*} context
     */
    constructor(options = {}, context = {}) {
        super(options, context);
        if (options.data) {
            this.data = DataSet.fromJSONCols(options.data.fields, options.data.columns);
        } else {
            this.data = DataSet.fromJSONCols([], []);
        }
        this.meta = options.meta;
        this.vizOptions = options.vizOptions;
        this.delay = options.delay || 0;
        this.errorLevel = options.errorLevel;
        this.error = options.error;
    }

    request() {
        return observer => {
            if (this.error) {
                observer.error({
                    level: this.errorLevel,
                    message: this.error,
                });
                return () => {};
            }

            const timer = setTimeout(() => {
                observer.next({
                    data: this.data,
                    meta: this.meta,
                    vizOptions: this.vizOptions,
                });
                observer.complete();
            }, this.delay);
            return () => {
                clearTimeout(timer);
            };
        };
    }
}
