import isEqual from '@splunk/dashboard-utils/jsonEqual';
import {
    definitionChanged,
    inputChanged,
    submittedTokenChanged,
    selectedVisualizationChanged,
} from '../../sagas/sagaActions';

import resetStore from '../resetStore';

/**
 * monitor redux store state and dispatch definition/tokens changed action for datasource saga
 * @param {*} getState
 */
export default function changeMonitor({ getState }) {
    return next => action => {
        const { definition, tokens, editor = {} } = getState();
        const oldDefinition = { ...definition };
        const oldTokens = { ...tokens };
        let returnValue = next(action); // hit reducer
        if (action.type === resetStore.toString()) {
            return returnValue;
        }
        const { definition: newDefinition, tokens: newTokens = {}, editor: newEditor = {} } = getState();
        if (!isEqual(oldDefinition, newDefinition)) {
            returnValue = next(definitionChanged());
        }
        if (!isEqual(oldDefinition.inputs, newDefinition.inputs)) {
            returnValue = next(inputChanged());
        }
        if (!isEqual(oldTokens.submitted, newTokens.submitted)) {
            returnValue = next(submittedTokenChanged());
        }
        if (!isEqual(editor.selectedVisualizations, newEditor.selectedVisualizations)) {
            returnValue = next(selectedVisualizationChanged());
        }
        return returnValue;
    };
}
