import { createTestHookFactory } from '@splunk/dashboard-utils/test';

export const createTestHook = createTestHookFactory({
    packageDir: 'unified-dashboard-framework',
    packageName: '@splunk/dashboard-core',
});

const srcDir = __dirname.replace(/\/utils$/, '');

const stripParentPath = (filename, dir) => {
    if (filename.slice(0, dir.length) === dir) {
        return filename.slice(dir.length);
    }
    return filename;
};

export const createTestHookElementFactory = filename => {
    const c = stripParentPath(filename, srcDir);
    function createElement(styledComponent, componentName) {
        const testHookAttrs = createTestHook(c, componentName);
        return styledComponent.attrs(testHookAttrs);
    }
    return createElement;
};
