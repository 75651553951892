import React from 'react';
import T from 'prop-types';
import { Provider } from 'react-redux';

/**
 * Custom Provider that inject components into context
 */
class DashboardProvider extends Provider {
    getChildContext() {
        const { eventRegistry, preset, apiRegistry, dataSourceRegistry } = this.props;
        return {
            ...super.getChildContext(),
            eventRegistry,
            preset,
            apiRegistry,
            dataSourceRegistry,
        };
    }

    render() {
        if (!this.props.children) {
            return null;
        }
        return React.Children.only(this.props.children);
    }
}

DashboardProvider.propTypes = {
    dataSourceRegistry: T.object,
    eventRegistry: T.object,
    apiRegistry: T.object,
    preset: T.object,
    ...Provider.propTypes,
};

DashboardProvider.childContextTypes = {
    dataSourceRegistry: T.object,
    eventRegistry: T.object,
    apiRegistry: T.object,
    preset: T.object,
    ...Provider.childContextTypes,
};

export default DashboardProvider;
