import { createAction, handleActions } from 'redux-actions';

/**
 * Actions
 */
export const toggleFullscreen = createAction('TOGGLE_FULLSCREEN');

/**
 *
 */
export const selectFullscreenElement = state => state.fullscreenElement;

/**
 * Reducer
 */
export default handleActions(
    {
        [toggleFullscreen]: (state, { payload }) => payload,
    },
    null
);
