import React from 'react';
import T from 'prop-types';
import Composer from 'react-composer';
import DataSourceConsumer from './DataSourceConsumer';

/**
 * A React component that allow consumer subscribe to multiple DataSources
 */
const DataSourceComposer = ({ consumerId, bindings, children }) => {
    if (bindings.length === 0) {
        children({ loading: false, dataSources: {}, updateRequestParams: () => {} });
    }
    const dataSourceConsumers = bindings.map(
        ({ bindingType, dataSource, dataSourceId, initialRequestParams }) => {
            return (
                <DataSourceConsumer
                    consumerId={consumerId}
                    bindingType={bindingType}
                    dataSource={dataSource}
                    dataSourceId={dataSourceId}
                    initialRequestParams={initialRequestParams}
                />
            );
        }
    );
    return (
        <Composer components={dataSourceConsumers}>
            {mergedResults => {
                // merge result into a single dataSources object
                const dataSources = {};
                const requestParamsUpdater = {};
                let primaryLoading;
                mergedResults.forEach(
                    ({ bindingType, loading, requestParams, updateRequestParams, data, meta, error }) => {
                        // we use primary dataSource to drive loading state
                        if (bindingType === 'primary') {
                            primaryLoading = loading;
                        }
                        requestParamsUpdater[bindingType] = updateRequestParams;
                        dataSources[bindingType] = {
                            requestParams,
                            data,
                            meta,
                            error,
                        };
                    }
                );

                return children({
                    loading: primaryLoading,
                    dataSources,
                    updateRequestParams: (bindingType, newRequestParams) => {
                        requestParamsUpdater[bindingType](newRequestParams);
                    },
                });
            }}
        </Composer>
    );
};

DataSourceComposer.propTypes = {
    /**
     * Consumer id
     */
    consumerId: T.string.isRequired,
    /**
     * render prop
     */
    children: T.func,
    /**
     * Consumer -- DataSource bindings
     */
    bindings: T.arrayOf(
        T.shape({
            bindingType: T.string,
            dataSource: T.object,
            dataSourceId: T.string,
            initialRequestParams: T.object,
        })
    ),
};

DataSourceComposer.defaultProps = {
    children: () => {},
};

export default DataSourceComposer;
