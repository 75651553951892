import mousetrap from 'mousetrap';
import EventListener from './EventListener';

/**
 * default keymap
 */
const DEFAULT_KEYMAP = [
    {
        event: 'move.left.unsnap',
        keys: 'shift+left',
        meta: { snap: false, dir: 'w' },
    },
    { event: 'move.left', keys: 'left', meta: { snap: true, dir: 'w' } },
    {
        event: 'move.right.unsnap',
        keys: 'shift+right',
        meta: { snap: false, dir: 'e' },
    },
    { event: 'move.right', keys: 'right', meta: { snap: true, dir: 'e' } },
    {
        event: 'move.up.unsnap',
        keys: 'shift+up',
        meta: { snap: false, dir: 'n' },
    },
    { event: 'move.up', keys: 'up', meta: { snap: true, dir: 'n' } },
    { event: 'move.down.unsnap', keys: 'shift+down', meta: { snap: false, dir: 's' } },
    { event: 'move.down', keys: 'down', meta: { snap: true, dir: 's' } },
    { event: 'copy', keys: 'meta+c', when: 'mac' },
    { event: 'copy', keys: 'ctrl+c', when: '!mac' },
    { event: 'paste', keys: 'meta+v', when: 'mac' },
    { event: 'paste', keys: 'ctrl+v', when: '!mac' },
    { event: 'duplicate', keys: 'meta+d', when: 'mac' },
    { event: 'duplicate', keys: 'ctrl+d', when: '!mac' },
    { event: 'selectAll', keys: 'meta+a', when: 'mac' },
    { event: 'selectAll', keys: 'ctrl+a', when: '!mac' },
    { event: 'selectNone', keys: 'meta+shift+a', when: 'mac' },
    { event: 'selectNone', keys: 'ctrl+shift+a', when: '!mac' },
    { event: 'removeItems', keys: 'backspace' },
    { event: 'removeItems', keys: 'del' },
    { event: 'cancel', keys: 'escape' },
    { event: 'undo', keys: 'meta+z', when: 'mac' },
    { event: 'undo', keys: 'ctrl+z', when: '!mac' },
    { event: 'redo', keys: 'meta+shift+z', when: 'mac' },
    { event: 'redo', keys: 'ctrl+shift+z', when: '!mac' },
];

/**
 * validate a key binding rule
 * @param {String} when
 * @return {Boolean} true indicate the rule is valid
 * @private
 */
const isValid = when => {
    const isMac = window.navigator.appVersion.indexOf('Mac') !== -1;
    return !when || (isMac && when === 'mac') || (!isMac && when === '!mac');
};

class KeyboardListener extends EventListener {
    /**
     * create a new KeyboardListener
     * @param {Object} keyMapConfig
     * @return {KeyboardListener} KeyboardListener instance
     */
    constructor(keyMapConfig = DEFAULT_KEYMAP) {
        super();
        this.updateKeyMap(keyMapConfig);
    }

    /**
     * update key mapping config
     * @param {Object} keyMapConfig
     * @public
     */
    updateKeyMap = (keyMapConfig = DEFAULT_KEYMAP) => {
        this.buildKeyMaps(keyMapConfig);
        this.setup();
    };

    /**
     * build reverse key mapping, using keys as the mapping key
     * @param {Object} keyMapConfig
     * @private
     */
    buildKeyMaps = keyMapConfig => {
        this.keyMaps = {};
        keyMapConfig.forEach(keyRule => {
            const valid = isValid(keyRule.when);
            if (valid) {
                if (this.keyMaps[keyRule.keys]) {
                    // eslint-disable-next-line no-console
                    console.warn(`duplicate key mapping detected for ${keyRule.keys}`);
                }
                this.keyMaps[keyRule.keys] = {
                    ...keyRule,
                };
            }
        });
    };

    /**
     * set up keyboard listener
     * @public
     */
    setup() {
        this.teardown();
        Object.keys(this.keyMaps).forEach(key => {
            mousetrap.bind(key, () => {
                let defaultPrevented = false;
                const payload = {
                    ...this.keyMaps[key].meta,
                    preventDefault: () => {
                        defaultPrevented = true;
                    },
                };
                const eventName = this.keyMaps[key].event;
                this.publishKeyEvent(eventName, payload);
                return !defaultPrevented;
            });
        });
    }

    /**
     * teardown all keyboard listener
     * @public
     */
    // eslint-disable-next-line class-methods-use-this
    teardown() {
        mousetrap.reset();
    }

    /** publish keyboard action event(s)
     * @param {Object} event
     * @private
     */
    publishKeyEvent = (eventName, payload) => {
        eventName.split('.').forEach((_, i, arr) => {
            this.publish(arr.slice(0, i + 1).join('.'), payload);
        });
    };
}

export { KeyboardListener as default, DEFAULT_KEYMAP };
