import { get } from 'lodash';
import { createAction, handleActions } from 'redux-actions';

export const setInputState = createAction('SET_INPUT_STATE');
export const updateInputState = createAction('UPDATE_INPUT_STATE', (id, state) => ({ id, state }));

export const selectInputState = state => state.inputState;

export const selectInputValue = (state, inputId) => get(state, ['inputState', inputId, 'value']);
/**
 * reducer
 */
export default handleActions(
    {
        [setInputState]: (currentState, { payload }) => payload,
        [updateInputState]: (currentState, { payload: { id, state } }) => ({
            ...currentState,
            [id]: state,
        }),
    },
    {}
);
