/* eslint-disable import/prefer-default-export */

/**
 * Takes an object with package information and returns a `createTestHook` function.
 *
 * The `createTestHook` function accepts a file name and additional options and returns an object
 * that can be passed as props to a react component for setting standard test hooks for automated
 * testing.
 *
 * @param {Object} options
 * @param {String} options.packageDir - The directory of the package.
 * @param {String} options.packageName - The name of the package.
 * @returns {Function} createTestHook
 * @public
 */
export function createTestHookFactory({ packageDir, packageName }) {
    /**
     * The `createTestHook` function is returned by `createTestHookFactory`. It creates an object that
     * can be passed as props to a react component for setting standard test hooks for automated
     * testing.
     *
     * @function createTestHook
     * @public
     * @param {String} fileName - The path of the file. Pass `__filename` to have webpack set this
     * dynamically at build time.
     * @param {String|Object} options - If a string is passed, it is used as the component name for the
     * test hook. Otherwise, the component name is accessed as a property of the options object. This
     * means that a components props can be passed here.
     * @returns {Object}
     */
    // eslint-disable-next-line no-unused-vars
    return function createTestHook(fileName, options) {
        const componentId = fileName
            .replace(`../${packageDir}`, '')
            .replace(/^\//, '')
            .replace(/^src\//, '')
            .replace(/\.jsx?$/, '');

        const componentName = typeof options === 'object' ? options.componentName : options;
        return {
            'data-component': fileName && `${packageName}:/${componentId}`,
            'data-test': componentName,
        };
    };
}

/**
 * test hooks
 */
export const hooks = {
    parts: {
        toolbar: 'toolbar',
        input: 'input',
        dashboard: 'dashboard',
        sidebar: 'sidebar',
    },
    clickable: 'clickable',
};
