/* eslint-disable no-underscore-dangle */
import { createStore as createReduxStore, combineReducers, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import changeMonitor from './middlewares/changeMonitor';
import actionListener from './middlewares/actionListener';
import definitionReducer from './definition';
import tokensReducer from './tokens';
import modeReducer from './mode';
import fullscreenReducer from './fullscreen';
import editorReducer from './editor';
import globalReducer from './global';
import inputStateReducer from './state/input';
import resetStoreAction from './resetStore';

export const resetStore = resetStoreAction;

const rootReducer = combineReducers({
    message: globalReducer,
    mode: modeReducer,
    fullscreenElement: fullscreenReducer,
    definition: definitionReducer,
    tokens: tokensReducer,
    editor: editorReducer,
    inputState: inputStateReducer,
});

const reducer = (state, action) => {
    if (action.type === resetStoreAction.toString()) {
        return action.payload;
    }
    return rootReducer(state, action);
};

export default function createStore({ initialState = {}, actionListenerDefs = {}, rootSaga }) {
    const sagaMiddleware = createSagaMiddleware();
    const middlewares = [changeMonitor, actionListener(actionListenerDefs), sagaMiddleware];
    let composeEnhancers = compose;

    // eslint-disable-next-line no-undef
    if (process.env.NODE_ENV !== 'production') {
        if (typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
            composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
                name: 'Dashboard Core',
            });
        }
    }
    const enhancers = [applyMiddleware(...middlewares)];
    const store = createReduxStore(reducer, initialState, composeEnhancers(...enhancers));
    sagaMiddleware.run(rootSaga);
    return store;
}
