/**
 * check if a url starts with http or https
 * @param {String} url
 */
export const isAbsoluteURL = url => {
    return /^https?:\/\//.test(url);
};

/**
 * check if a url is a valid relative url
 * @param {String} url
 */
export const isRelativeURL = url => {
    return url[0] === '/' && !/^\/\//.test(url);
};

/**
 * check if a url is consider as valid
 * @param {String} url
 */
export const isValidUrl = url => {
    return isAbsoluteURL(url) || isRelativeURL(url);
};
