/* eslint-disable no-restricted-syntax, no-constant-condition */

import { take, cancel, fork } from 'redux-saga/effects';
import { teardown } from './sagaActions';
import inputSaga from './inputSaga';
import eventSaga from './eventSaga';
import editingSaga from './editingSaga';
import dataSourceSaga from './dataSourceSaga';

export default (sagaContext = {}) =>
    function* mainSaga() {
        const dataSourceTask = yield fork(dataSourceSaga, sagaContext);
        const inputUpdateTask = yield fork(inputSaga, sagaContext);
        const eventHandlingTask = yield fork(eventSaga, sagaContext);
        const editingSagaTask = yield fork(editingSaga, sagaContext);
        yield take(teardown);
        yield cancel(dataSourceTask);
        yield cancel(inputUpdateTask);
        yield cancel(eventHandlingTask);
        yield cancel(editingSagaTask);
    };
