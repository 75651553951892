/* eslint-disable import/prefer-default-export */
import { createAction } from 'redux-actions';

/**
 * Actions that communicate with Saga, these actions will not update redux store directly (no reducer)
 */
export const initialize = createAction('SAGA_INITIALIZE');
export const teardown = createAction('SAGA_TEARDOWN');

export const removeVisualizations = createAction('REMOVE_VISUALIZATIONS');
export const createVisualization = createAction('CREATE_VISUALIZATIONS');
export const cloneVisualizations = createAction('CLONE_VISUALIZATIONS');
export const adjustVisualizationOrder = createAction('ADJUST_VIZ_ORDER', (from, to) => ({
    from,
    to,
}));

export const updateVizRequestParams = createAction(
    'UPDATE_VIZ_REQUEST_PARAMS',
    (vizId, dataSourceType, newRequestParams) => ({ vizId, dataSourceType, newRequestParams })
);

export const triggerEvent = createAction('EVENT_TRIGGERED', (targetId, eventType, eventPayload, eventId) => ({
    targetId,
    eventType,
    eventPayload,
    eventId,
}));

export const inputValueChanged = createAction('INPUT_VALUE_CHANGED', (id, newValue, eventId) => ({
    id,
    value: newValue,
    eventId,
}));

export const definitionChanged = createAction('DEFINITION_CHANGED');
export const submittedTokenChanged = createAction('SUBMITTED_TOKEN_CHANGED');
export const inputChanged = createAction('INPUT_CHANGED');
export const selectedVisualizationChanged = createAction('SELECTED_VISAULIZATIONS_CHANGED');
