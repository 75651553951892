import each from 'lodash/each';
import isEmpty from 'lodash/isEmpty';

const keyPattern = '^[a-zA-Z0-9_-]*$';
const defaultSchema = {
    $id: 'http://www.splunk.com/dashboard.schema.json',
    title: 'Dashboard Definition',
    description: 'Dashboard Definition',
    type: 'object',
    properties: {
        title: {
            type: ['string', 'null'],
        },
        description: {
            type: ['string', 'null'],
        },
        dataSources: {
            patternProperties: {
                [keyPattern]: { $ref: '#/definitions/DataSource' },
            },
        },
        visualizations: {
            patternProperties: {
                [keyPattern]: { $ref: '#/definitions/Visualization' },
            },
        },
        inputs: {
            patternProperties: {
                [keyPattern]: { $ref: '#/definitions/Input' },
            },
        },
        layout: { $ref: '#/definitions/Layout' },
    },
    required: ['layout', 'dataSources', 'visualizations'],
    definitions: {
        Layout: {
            type: 'object',
            properties: {
                type: {
                    type: 'string',
                },
                options: {
                    type: 'object',
                },
                globalInputs: {
                    type: 'array',
                    items: {
                        type: 'string',
                    },
                },
                structure: {
                    type: 'array',
                },
            },
            required: ['type'],
        },
        DataSource: {
            type: 'object',
            properties: {
                type: {
                    type: 'string',
                },
                options: {
                    type: 'object',
                },
                // datasource name
                name: {
                    type: 'string',
                },
                // some metadata for this data source
                meta: {
                    type: 'object',
                },
            },
            required: ['type'],
        },
        Visualization: {
            type: 'object',
            properties: {
                type: {
                    type: 'string',
                },
                options: {
                    type: 'object',
                },
                encoding: {
                    type: 'object',
                },
                dataSources: {
                    patternProperties: {
                        [keyPattern]: { type: 'string' },
                    },
                },
                eventHandlers: {
                    type: 'array',
                    items: { $ref: '#/definitions/EventHandler' },
                },
                title: {
                    type: 'string',
                },
                description: {
                    type: 'string',
                },
            },
            required: ['type'],
        },
        Input: {
            type: 'object',
            properties: {
                type: {
                    type: 'string',
                },
                options: {
                    type: 'object',
                },
                encoding: {
                    type: 'object',
                },
                dataSources: {
                    patternProperties: {
                        [keyPattern]: { type: 'string' },
                    },
                },
                title: {
                    type: 'string',
                },
            },
            required: ['type'],
        },
        EventHandler: {
            type: 'object',
            properties: {
                type: {
                    type: 'string',
                },
                options: {
                    type: 'object',
                },
                name: {
                    type: 'string',
                },
            },
            required: ['type'],
        },
    },
};

/**
 * create new schema
 * @method createMonacoSchema
 * @param {object} config
 * @param {object} config.newSchema
 * @param {string} config.modelUri
 * @returns {object} monaco schema
 */
export const createMonacoSchema = ({ newSchema = {}, modelUri }) => {
    const finalSchema = isEmpty(newSchema) ? defaultSchema : newSchema;
    return [
        {
            // made up fake uri
            uri: 'http://splunk/json-schema.json',
            fileMatch: [modelUri],
            schema: finalSchema,
        },
    ];
};

/**
 * combine schemas for the same type
 * @method combineSchema
 * @param {object} componentDict {type : optionSchema}
 * @returns {array} allOf statement of if else statements
 */
export const combineSchema = componentDict => {
    const allOfStatement = [];
    each(componentDict, (optionSchema, type) => {
        if (!isEmpty(optionSchema)) {
            const statement = {
                if: {
                    properties: { type: { const: type } },
                },
                then: {
                    properties: {
                        options: {
                            type: 'object',
                            properties: optionSchema,
                            // TODO: Revisit additionalProperties to disallow unkown keys as a warning
                            additionalProperties: true,
                        },
                    },
                },
            };
            allOfStatement.push(statement);
        }
    });
    return allOfStatement;
};

/**
 * @method createSchemaBasedOnDicts
 * @param {object} config
 * @param {object} layoutDict {type : optionSchema}
 * @param {object} dataSourceDict {type : optionSchema}
 * @param {object} visualizationDict {type : optionSchema}
 * @param {object} inputDict {type : optionSchema}
 * @returns {object} schema
 */
export const createSchemaBasedOnDicts = ({
    layoutDict = {},
    dataSourceDict = {},
    visualizationDict = {},
    inputDict = {},
}) => {
    const layoutAllOfStatement = combineSchema(layoutDict);
    const dataSourceAllOfStatement = combineSchema(dataSourceDict);
    const visualizationAllOfStatement = combineSchema(visualizationDict);
    const inputAllOfStatement = combineSchema(inputDict);
    const newSchema = defaultSchema;
    newSchema.$id = 'http://www.splunk.com/dashboard.newSchema.json';
    newSchema.additionalProperties = false;
    newSchema.definitions.Layout.additionalProperties = false;
    newSchema.definitions.Input.additionalProperties = false;
    newSchema.definitions.Visualization.additionalProperties = false;
    newSchema.definitions.DataSource.additionalProperties = false;
    newSchema.definitions.EventHandler.additionalProperties = false;
    if (!isEmpty(layoutDict)) {
        newSchema.definitions.Layout.properties.type.enum = Object.keys(layoutDict);
    }
    if (!isEmpty(layoutAllOfStatement)) {
        newSchema.definitions.Layout.allOf = layoutAllOfStatement;
    }
    if (!isEmpty(inputDict)) {
        newSchema.definitions.Input.properties.type.enum = Object.keys(inputDict);
    }
    if (!isEmpty(inputAllOfStatement)) {
        newSchema.definitions.Input.allOf = inputAllOfStatement;
    }
    if (!isEmpty(visualizationDict)) {
        newSchema.definitions.Visualization.properties.type.enum = Object.keys(visualizationDict);
    }
    if (!isEmpty(visualizationAllOfStatement)) {
        newSchema.definitions.Visualization.allOf = visualizationAllOfStatement;
    }
    if (!isEmpty(dataSourceDict)) {
        newSchema.definitions.DataSource.properties.type.enum = Object.keys(dataSourceDict);
    }
    if (!isEmpty(dataSourceAllOfStatement)) {
        newSchema.definitions.DataSource.allOf = dataSourceAllOfStatement;
    }
    return newSchema;
};

/**
 * create component dictionary based on preset
 * @method createComponentDict
 * @param {object} preset
 * @returns {object} component object {type : optionSchema}
 */
export const createComponentDict = preset => {
    const componentDict = {};
    each(preset, (component, type) => {
        componentDict[type] = component.schema;
    });
    return componentDict;
};

/**
 * create schema based on presets
 * @method createSchemaBasedOnPresets
 * @param {object} presets combined with all custom presets
 * @returns {object} schema
 */
export const createSchemaBasedOnPresets = presets => {
    const layoutDict = createComponentDict(presets.layouts);
    const dataSourceDict = createComponentDict(presets.dataSources);
    const visualizationDict = createComponentDict(presets.visualizations);
    const inputDict = createComponentDict(presets.inputs);
    return createSchemaBasedOnDicts({ layoutDict, dataSourceDict, visualizationDict, inputDict });
};
export default defaultSchema;
