import { Component } from 'react';
import T from 'prop-types';
import isEqual from '@splunk/dashboard-utils/jsonEqual';

const INITIAL_STATE = {
    error: null,
    requestParams: null,
    data: null,
    meta: null,
};

/**
 * A DataSource Consumer react component, data/error will be exposed via a render prop
 */
class DataSourceConsumer extends Component {
    static propTypes = {
        /**
         * Consumer Id, should either be viz id or input id
         */
        consumerId: T.string.isRequired,
        /**
         * initial request params.
         */
        initialRequestParams: T.object,
        /**
         * binding type such as `primary`, `annotation`
         */
        bindingType: T.string.isRequired,
        /**
         * DataSource definition
         */
        dataSource: T.object.isRequired,
        /**
         * DataSource id
         */
        dataSourceId: T.string.isRequired,
        /**
         * render prop
         */
        children: T.func,
    };

    static contextTypes = {
        /**
         * DataSource registry from context
         */
        dataSourceRegistry: T.object,
    };

    static defaultProps = {
        initialRequestParams: {
            offset: 0,
            count: 1000,
        },
        children: () => {},
    };

    constructor(props, context) {
        super(props, context);
        this.dataSourceRegistry = context.dataSourceRegistry;
        this.state = INITIAL_STATE;
    }

    componentDidUpdate = async prevProps => {
        if (this.shouldResetSubscription(prevProps)) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState(INITIAL_STATE);
            if (this.sub) {
                this.sub.cancel();
            }
            await this.createSubscription();
        }
    };

    componentWillUnmount() {
        // cancel existing subscriptions
        if (this.sub) {
            this.sub.cancel();
        }
    }

    /**
     * create DataSource subscription. This creates a Consumer - DataSource binding.
     */
    createSubscription = async () => {
        const { consumerId, dataSource, dataSourceId, initialRequestParams } = this.props;
        const dsController = this.dataSourceRegistry.allocate({ dataSourceDef: dataSource, dataSourceId });
        this.sub = await dsController.subscribe({ consumerId, initialRequestParams });
        this.sub.subscribeToData({ onData: this.handleData, onError: this.handleError });
    };

    /**
     * Basically if DataSource definition changes, we need to reset the subscription
     */
    shouldResetSubscription = prevProps => {
        const { dataSource, dataSourceId } = this.props;
        if (dataSourceId !== prevProps.dataSourceId) {
            return true;
        }
        if (!isEqual(dataSource, prevProps.dataSource)) {
            return true;
        }
        return false;
    };

    /**
     * set data
     */
    handleData = ({ requestParams, data, meta }) => {
        this.setState({
            error: null,
            requestParams,
            data,
            meta,
        });
    };

    /**
     * set error
     */
    handleError = ({ level, message }) => {
        this.setState({
            error: {
                level,
                message,
            },
        });
    };

    /**
     * update request params via subscription
     */
    updateRequestParams = newRequestParams => {
        if (this.sub) {
            this.sub.updateRequestParams(newRequestParams);
        }
    };

    componentDidMount = async () => {
        await this.createSubscription();
    };

    render() {
        const { error, requestParams, data, meta } = this.state;
        const { bindingType, children } = this.props;
        // set loading = true when no error and data
        const loading = error == null && data == null;
        return children({
            loading,
            requestParams,
            updateRequestParams: this.updateRequestParams,
            data,
            meta,
            bindingType,
            error,
        });
    }
}

export default DataSourceConsumer;
