import pick from 'lodash/pick';
import each from 'lodash/each';
import cloneDeep from 'lodash/cloneDeep';
import uniqueId from 'lodash/uniqueId';
import get from 'lodash/get';
import isFunction from 'lodash/isFunction';

export const assembleSnapshot = async ({ definition, tokens = {}, dataSourceRegistry, apiRegistry }) => {
    const snapshotDefinition = cloneDeep(definition);
    const dataSourceSnapshot = dataSourceRegistry.snapshot();
    // replace data source by a special type of data source which holds the search result. By doing this the definition becomes self-contained.
    const dsDefs = {};
    each(snapshotDefinition.visualizations, (vizDef, vizId) => {
        const dataSources = get(vizDef, 'dataSources', {});
        const newBindings = {};
        each(dataSources, (dsId, dsType) => {
            const dsDef = get(snapshotDefinition, ['dataSources', dsId], {});
            const dsState = get(dataSourceSnapshot, [dsId, vizId], {});
            const newDsId = uniqueId('ds_');
            dsDefs[newDsId] = {
                ...dsDef,
                state: {
                    ...pick(dsState, ['requestParams', 'meta', 'error']),
                },
            };
            newBindings[dsType] = newDsId;
        });
        snapshotDefinition.visualizations[vizId].dataSources = newBindings;
        // get snapshot of each visualization if applicable
        const vizApi = apiRegistry.getVisualizationApi(vizId);
        if (vizApi && isFunction(vizApi.snapshot)) {
            snapshotDefinition.visualizations[vizId] = {
                ...snapshotDefinition.visualizations[vizId],
                ...vizApi.snapshot(),
            };
        }
    });
    snapshotDefinition.dataSources = dsDefs;

    // replace background image with encoding
    const layoutApi = apiRegistry.getLayoutApi();
    if (layoutApi && isFunction(layoutApi.snapshot)) {
        snapshotDefinition.layout = {
            ...snapshotDefinition.layout,
            ...layoutApi.snapshot(),
        };
    }

    // remove inputs
    delete snapshotDefinition.inputs;
    delete snapshotDefinition.layout.globalInputs;

    // remove submit button
    if (snapshotDefinition.layout.options) {
        delete snapshotDefinition.layout.options.submitButton;
    }

    const snapshot = {
        snapshotDefinition,
        tokens,
    };
    return snapshot;
};
