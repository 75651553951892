import memoizeOne from 'memoize-one';
import React, { useEffect, createContext, useMemo } from 'react';
import T from 'prop-types';
import { GeoContextProvider } from '@splunk/visualization-context/GeoContext';
import { ImageContextProvider } from '@splunk/visualization-context/ImageContext';
import { IconContextProvider } from '@splunk/visualization-context/IconContext';
import KeyboardListener from './KeyboardListener';
import DefaultFeatureFlags from './FeatureFlags';

export const DashboardContext = createContext({});

const createFeatureFlags = memoizeOne(flags => {
    return { ...DefaultFeatureFlags, ...flags };
});

/**
 * dashboard context provider that provides contextual objects over the react render tree
 * @param {*} param01
 *
 */
const DashboardContextProvider = ({
    children,
    contentExportClient,
    dataSourceContext,
    featureFlags,
    geoRegistry,
    iconRegistry,
    imageRegistry,
    keyboardListener,
    logger,
    telemetryChannel,
}) => {
    // only call setup() when component mount, and only call teardown() when component unmount
    useEffect(() => {
        keyboardListener.setup();

        return () => keyboardListener.teardown();
    }, [keyboardListener]);

    const value = useMemo(
        () => ({
            contentExportClient,
            dataSourceContext,
            featureFlags: createFeatureFlags(featureFlags),
            geoRegistry,
            iconRegistry,
            imageRegistry,
            keyboardListener,
            logger,
            telemetryChannel,
        }),
        [
            contentExportClient,
            dataSourceContext,
            featureFlags,
            geoRegistry,
            iconRegistry,
            imageRegistry,
            keyboardListener,
            logger,
            telemetryChannel,
        ]
    );
    return (
        <DashboardContext.Provider value={value}>
            <GeoContextProvider value={geoRegistry}>
                <ImageContextProvider value={imageRegistry}>
                    <IconContextProvider value={iconRegistry}>{children}</IconContextProvider>
                </ImageContextProvider>
            </GeoContextProvider>
        </DashboardContext.Provider>
    );
};

DashboardContextProvider.propTypes = {
    /**
     * an icon registry instance
     */
    iconRegistry: T.object,
    /**
     * an image registry instance
     */
    imageRegistry: T.object,
    /**
     * a geo registry instance
     */
    geoRegistry: T.object,
    /**
     * global keyboard listener
     */
    keyboardListener: T.instanceOf(KeyboardListener),
    /**
     * global dashboard context object
     */
    dataSourceContext: T.object,
    /**
     * telemetry channel for sending UDF definition and logging events
     */
    telemetryChannel: T.object,
    /**
     * Client implementation of API workflow with Content Export service
     */
    contentExportClient: T.object,
    /**
     * Collection of feature flags to override/introduce
     */
    featureFlags: T.object,
    /**
     * children node
     */
    children: T.any,
    /**
     * logger for error, warning, and info level events
     */
    logger: T.object,
};

DashboardContextProvider.defaultProps = {
    keyboardListener: new KeyboardListener(),
    dataSourceContext: {},
};

const DashboardContextConsumer = DashboardContext.Consumer;

export { DashboardContext as default, DashboardContextProvider, DashboardContextConsumer };
