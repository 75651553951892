import isFunction from 'lodash/isFunction';
import console from '@splunk/dashboard-utils/console';

/**
 * a custom action listener implemented as an redux middleware
 */
export default (actionListeners = {}) => () => next => action => {
    const returnValue = next(action); // hit reducer
    if (actionListeners[action.type]) {
        try {
            if (isFunction(actionListeners[action.type])) {
                actionListeners[action.type](action);
            }
        } catch (ex) {
            console.error(ex);
        }
    }
    return returnValue;
};
