import React from 'react';
import T from 'prop-types';
import styled from 'styled-components';
import SplunkUIMessage from '@splunk/react-ui/Message';

const MessageContainer = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 15px;
`;

const Message = ({ level, message }) => (
    <MessageContainer data-test="dashboard-message">
        <SplunkUIMessage type={level}>{message}</SplunkUIMessage>
    </MessageContainer>
);

Message.propTypes = {
    /**
     * message text
     */
    message: T.string,
    /**
     * message level
     */
    level: T.oneOf(['info', 'warning', 'error']),
};

Message.defaultProps = {
    level: 'info',
    message: '',
};
export default Message;
